import React, {useEffect, useState} from "react";
import Segment from "../../../components/Segment";
import RatesTable from "./_components/ratesTable";
import Loader from "../../../components/Loader";
import API from "../../../api";
import { PencilIcon, PlusIcon } from "@heroicons/react/solid";
import { Button } from "../../../components/Forms";
import { useParams } from "react-router-dom";

const FeeScalesSettings = ({history}) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [globalError, setGlobalError] = useState(null);
  const [feeSets, setFeeSets] = useState([]);

  const params = useParams();

  const getData = () => {
    setGlobalError(null);
    Promise.all([
      API.settings.fee_scales().list(params.org_id),
    ]).then(res => {
      setFeeSets(res[0]);
    }).catch(err => {
      if(err.response !== undefined){
        setGlobalError(err.response.data.message);
      } else {
        setGlobalError("There was an unexpected error while trying to retrieve the base rates from the server.");
      }
    }).finally(() => {
      setIsInitialLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  if(isInitialLoading){
    return (
      <Loader>Retrieving data from server...</Loader>
    );
  }
  if(globalError){
    return (
      <div className={"m-4 p-2 bg-red-600 text-white"}>{globalError}</div>
    );
  }

  const renderTable = (header, body, h1, p) => (
    <div className={` text-lg`}>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            {h1 && <h1 className="text-xl font-semibold text-gray-900">
              {h1}
            </h1>}
            {p && <p className="mt-2 text-sm text-gray-700">
              {p}
            </p>}
          </div>
          {(h1 || p) &&
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              onClick={() => history.push(params.org_id ? `/admin/organisations/${params.org_id}/fee_scales/add` : "/settings/fee_scales/add")}
              type="button"
              className="bg-green-500 inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              Add
            </button>
          </div>}
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Set
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Scale
                      </th>

                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        {!(h1 || p) && <button
                            onClick={() => history.push(params.org_id ? `/admin/organisations/${params.org_id}/fee_scales/add` : "/settings/fee_scales/add")}
                            type="button"
                            className="bg-green-500 inline-flex items-center justify-center rounded-md border border-transparent px-2 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto"
                          >
                            <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                            Add
                          </button>}
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {body.length > 0 ? body.map((body_item, body_index) => (
                      <tr key={body_index}>
                        {header.map((header_item, header_index) => (
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {body_item[header_item.value]}
                          </td>
                        ))}
                      </tr>
                    )) : <tr>
                        <td className="text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" colSpan={header.length}>No Data Found!</td>
                      </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="sm:grid grid-cols-2">
        <Segment>
          <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
            <span className="my-auto text-xl">Fee Recovery Scales [Fixed / % / Sliding Scale, Stage & Hourly]</span>
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />

          {
          renderTable(
            [
              { text: "Set", value: "name" },
              { text: "Scale", value: "scale" },
              { text: "Actions", value: "action_btn" },
            ],
            feeSets.map((item) => {
              return {
                ...item,
                scale: (item.scale === "slidingScale" ? "Sliding" : item.scale === "stageScale" ? "Stage" : item.scale === "hourlyScale" ? "Hourly" : ""),
                action_btn: (
                  <div className="mx-auto md:grid md:grid-cols-2 md:gap-x-0">
                    <Button
                      icon={PencilIcon}
                      colour="action"
                      className="md:ml-auto md:mr-2 mb-2 md:mb-0"
                      onClick={() => {
                        history.push(
                          params.org_id ? `/admin/organisations/${params.org_id}/fee_scales/edit/${item._id}` :
                          "/settings/fee_scales/edit/" + item._id
                        );
                      }}
                    />
                    {/*  
                  <Button
                    icon={TrashIcon}
                    colour="red"
                    className="md:mr-auto md:mr-2"
                    onClick={() => {
                      setModalOpen(item._id);
                    }}
                  />*/}
                  </div>
                ),
              };
            })
          )
        }


          {/* <RatesTable rates={baseRates} onCreate={onRateCreate} onUpdate={onRateUpdate} onDelete={onRateDelete} /> */}
        </Segment>
       
      </div>
      
    </React.Fragment>
  );
}
export default FeeScalesSettings;