import React, { useEffect, useState } from "react";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/solid";
import DayJS from "dayjs";
import { Button, Input, SelectTwo } from "../../../components/Forms";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import { GiCheckMark, GiMagnifyingGlass } from "react-icons/gi";
import { DataGridPro, GridRowsProp, GridColDef, GridRowOrderChangeParams } from "@mui/x-data-grid-pro";

const LicenceTable = ({ data, licenceTypes, onCreate, onUpdate, onReorder , onDelete = () => {} }) => {
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    user: "",
    seats: 0,
    price: 0,
    frequency: "",
    archived: false,
    subscription: "",
    duration: 0,
    payment: "",
    sort: data.length,
    // discount: "",
  });
  const [submitError, setSubmitError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    let errors = {};
    if (formData.name === "") {
      errors.name = "Name can not be empty";
    }
    if (formData.description === "") {
      errors.description = "Description can not be empty";
    }
    if (!formData.price > 0) {
      errors.price = "Price can not be empty";
    }
    if (formData.payment === "") {
      errors.payment = "Payment Type can not be empty";
    }
    if (formData.subscription === "") {
      errors.subscription = "User Type can not be empty";
    }
    if (!formData.seats > 0) {
      // errors.seats = "Seats can not be empty";
    }
    if (!formData.subscription === "") {
      errors.subscription = "Type can not be empty";
    }
    if (!formData.frequency === "") {
      errors.frequency = "Duration Period can not be empty";
    }

    setFormErrors(errors);
  }, [formData]);

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  const handlePriceChange = (index, field, value) => {
    setFormData(prevFormData => {
      const newPrices = [...prevFormData.prices];
      newPrices[index] = {
        ...newPrices[index],
        [field]: value
      };
      return {
        ...prevFormData,
        prices: newPrices
      };
    });
  };

  const handleFormSubmit = () => {
    setFormIsSubmitting(true);
    setSubmitError(null);
    let apiAction;
    if (formData._id === undefined) {
      // Create new
      apiAction = onCreate(formData);
    } else {
      // Editing
      apiAction = onUpdate(formData._id, formData);
    }
    apiAction
      .then(() => {
        setFormData({
          name: "",
          description: "",
          user: "",
          // seats: 0,
          price: 0,
          frequency: "",
          archived: false,
          subscription: "",
          duration: 0,
          payment: "",
          sort: data.length + 1,
          // discount: "",
        }); // Reset the form
        setEditing(false);
      })
      .catch((err) => {
        if (err.isAxiosError !== undefined) {
          setSubmitError(err.response.data.message);
        } else {
          setSubmitError(
            "There was an unexpected error while processing data."
          );
        }
      })
      .finally(() => {
        setFormIsSubmitting(false);
      });
  };
  const handleDeleteSubmit = () => {
    setShowDelete(false);
    setFormIsSubmitting(true);
    setSubmitError(null);
    onDelete(formData._id)
      .then(() => {
        setFormData({
          name: "",
          description: "",
          user: "",
          // seats: 0,
          price: 0,
          frequency: "",
          archived: false,
          subscription: "",
          duration: 0,
          payment: "",
          // discount: "",
        }); // Reset the form
      })
      .catch((err) => {
        if (err.isAxiosError !== undefined) {
          setSubmitError(err.response.data.message);
        } else {
          setSubmitError(
            "There was an unexpected error while processing data."
          );
        }
      })
      .finally(() => {
        setFormIsSubmitting(false);
      });
  };

  let arr = data.map((d, index) => {
    return {
      sort: d.sort ? d.sort : index,
      id: d._id,
      name: d.name,
      archived: d.archived,
      description: d.description,
      // subscription: d.subscription,
      subscription: d.subscription ? d.subscription._id : null,
      subscription_name: d.subscription ? d.subscription.name : "",
      // price: d.price, {/*d.discount ? `(${d.discount})` : ""*/}
      price: d.price,
      price_text: `£${d.price} - ${d.payment === "recurring" ? `${d.duration} ${d.frequency}(s)` : "One-off Payment"}`,
      payment: d.payment,
    }
  });
  

  const rows: GridRowsProp = arr.length > 0 ? arr.sort((a,b) => a.sort > b.sort ? 1 : -1) : [];
  // const rows: GridRowsProp = arr.length > 0 ? arr : [];

  const columns: GridColDef[] = [
    { headerName: "Sort", field: "sort", width: 60, pinnable: false },
    {
      field: 'name',
      headerName: 'Title',
      width: 300,
      renderCell: (params) => {
        const name = params.row.name;
        const archived = params.row.archived;
        return (
          <>{name + " "}{archived && <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
            Archived
          </span>}</>
        );
      },
    },
    { headerName: "Description", field: "description", width: 500, pinnable: false },
    { headerName: "User Type", field: "subscription_name", width: 100, pinnable: false },
    { headerName: "Charge", field: "price_text", width: 200, pinnable: false },
    { headerName: "Payment Type", field: "payment", width: 200, pinnable: false },
    {
      field: 'actions',
      headerName: '',
      width: 60,
      renderCell: (params) => {
        const d = params.row;
        return (
          <Button
            colour="action"
            // onClick={() => history.push(url)}
            onClick={() => {
              setEditing(true);
              setFormData({
                _id: d.id,
                name: d.name,
                description: d.description,
                price: d.price,
                sort: d.sort,
                // seats: d.seats,
                archived: d.archived,
                user: d.user,

                frequency: d.frequency,
                subscription: d.subscription,
                duration: d.duration,
                // discount: d.discount,
                payment: d.payment !== "single" ? (d.frequency === "month" ? "monthly" : "yearly") : d.payment,

              });
            }}
            icon={PencilIcon}
          />
        );
      },
    }
  ];

  const handleRowOrderChange = (params) => {    
    const { oldIndex, targetIndex } = params;

    // Create a copy of the array
    const newArr = [...arr];

    // Remove the item from the old index and store it temporarily
    const [movedItem] = newArr.splice(oldIndex, 1);

    // Insert the item at the target index
    newArr.splice(targetIndex, 0, movedItem);

    console.log("🚀 ~ Updated Array:", newArr);

    onReorder(newArr);
    return newArr;

  };

  return (
    <div>
      {submitError && (
        <div
          className={"p-2 border rounded border-red-800 bg-red-700 text-white"}
        >
          {submitError}
        </div>
      )}
      {showDelete && (
        <ConfirmModal
          handleClose={() => setShowDelete(false)}
          open={true}
          header={"Are you sure?"}
          content={
            "Are you sure you like to remove this Licence, this action can't be undone."
          }
          handleSubmit={handleDeleteSubmit}
        />
      )}

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

          <div className="border-b border-gray-900/10 pb-12">

          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-2">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Sort
              </label>
              <div className="mt-2">
                <Input
                  type="number"
                  name="sort"
                  value={formData.sort}
                  onChange={handleOnChange}
                  disabled={formIsSubmitting}
                  min="0"
                  // max={data.length}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <div className="mt-2">
                <Input
                  name="name"
                  value={formData.name}
                  onChange={handleOnChange}
                  error={formErrors.name}
                  disabled={formIsSubmitting}
                />
                {formErrors.name && (
                  <span className={"p-1 text-red-500"}>
                    {formErrors.name}
                  </span>
                )}
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Description
              </label>
              <div className="mt-2">
                <Input
                  name="description"
                  value={formData.description}
                  onChange={handleOnChange}
                  error={formErrors.description}
                  disabled={formIsSubmitting}
                />
                {formErrors.description && (
                  <span className={"p-1 text-red-500"}>
                    {formErrors.description}
                  </span>
                )}
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                User Type
              </label>
              <div className="mt-2">
                <SelectTwo
                  field="subscription"
                  value={formData.subscription}
                  options={licenceTypes}
                  onChange={handleOnChange}
                  disabled={formIsSubmitting}
                />
                {formErrors.subscription && (
                    <span className={"p-1 text-red-500"}>
                      {formErrors.subscription}
                    </span>
                  )}
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Payment Type
              </label>
              <div className="mt-2">
                <SelectTwo
                  field="payment"
                  value={formData.payment}
                  options={[
                    {text: "Single Payment", value: "single"},
                    {text: "Recurring Monthly (Pro-Rata)", value: "monthly"},
                    {text: "Recurring Yearly", value: "yearly"},
                  ]}
                  onChange={(e) => {

                    if(e.target.value === "yearly"){
                      setFormData({
                        ...formData,
                        frequency: "year",
                        duration: 1,
                        payment: "recurring",
                      });
                    }else if(e.target.value === "monthly"){
                      setFormData({
                        ...formData,
                        frequency: "month",
                        duration: 1,
                        payment: "recurring",
                      });
                    }else{
                      setFormData({
                        ...formData,
                        payment: e.target.value,
                      });
                    }

                    
                  }}
                  disabled={formIsSubmitting}
                />
                {formErrors.payment && (
                    <span className={"p-1 text-red-500"}>
                      {formErrors.payment}
                    </span>
                  )}
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Duration Period
              </label>
              <div className="mt-2">
                <SelectTwo
                  field="frequency"
                  value={formData.frequency}
                  options={[
                    {text: "day", value: "day"},
                    {text: "month", value: "month"},
                    {text: "year", value: "year"}
                  ]}
                  onChange={handleOnChange}
                  disabled={formIsSubmitting || formData.payment !== "single"}
                />
                {formErrors.frequency && (
                    <span className={"p-1 text-red-500"}>
                      {formErrors.frequency}
                    </span>
                  )}
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Duration of the Licence
              </label>
              <div className="mt-2">
                <SelectTwo
                  field="duration"
                  value={formData.duration}
                  options={[
                    {text: "1", value: 1},
                    {text: "3", value: 3},
                    {text: "6", value: 6},
                  ]}
                  onChange={handleOnChange}
                  disabled={formIsSubmitting || formData.payment !== "single"}
                />
                {formErrors.duration && (
                    <span className={"p-1 text-red-500"}>
                      {formErrors.duration}
                    </span>
                  )}
              </div>
            </div>

            {/*  

            <div className="sm:col-span-2">
              <label htmlFor="seats" className="block text-sm font-medium leading-6 text-gray-900">
                Seats
              </label>
              <div className="mt-2">
                  <Input
                    type="number"
                    name="seats"
                    value={formData.seats}
                    onChange={handleOnChange}
                    error={formErrors.seats}
                    disabled={formIsSubmitting}
                  />
                  {formErrors.seats && (
                    <span className={"p-1 text-red-500"}>
                      {formErrors.seats}
                    </span>
                  )}
              </div>
            </div>*/}

            <div className="sm:col-span-2">
              <label htmlFor="seats" className="block text-sm font-medium leading-6 text-gray-900">
                Charge
              </label>
              <div className="mt-2">
                  <Input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleOnChange}
                    error={formErrors.price}
                    disabled={formIsSubmitting}
                  />
                  {formErrors.price && (
                    <span className={"p-1 text-red-500"}>
                      {formErrors.price}
                    </span>
                  )}
              </div>
            </div>

            {/*  

            <div className="sm:col-span-2">
              <label htmlFor="seats" className="block text-sm font-medium leading-6 text-gray-900">
                Discount (To show on front-end)
              </label>
              <div className="mt-2">
                  <Input
                    type="text"
                    name="discount"
                    value={formData.discount}
                    onChange={handleOnChange}
                    disabled={formIsSubmitting}
                  />
              </div>
            </div>*/}

          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
              {editing && (
                  <Button
                    className="text-sm font-semibold leading-6 text-gray-900"
                    label={formData.archived ? "Un-Archive" : "Archive"}
                    colour={formData.archived ? "postive" : "alert"}
                    icon={formData.archived ? GiCheckMark : TrashIcon}
                    disabled={
                      Object.keys(formErrors).length > 0 ||
                      formIsSubmitting
                    }
                    onClick={handleDeleteSubmit}
                  />
                )}
          <Button
              label={editing ? "Save" : "Add"}
              colour={editing ? "warning" : "positive"}
              icon={editing ? PencilIcon : PlusIcon}
              className={"rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"}
              disabled={
                Object.keys(formErrors).length > 0 ||
                formIsSubmitting || (editing && formData.archived)
              }
              onClick={handleFormSubmit}
            />
        </div>

        </div>

            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

              <DataGridPro 
                rows={rows} 
                columns={columns} 
                initialState={{ pinnedColumns: {left: ['sort'], right: ['actions'] } }}
                // rowReordering
                // onRowOrderChange={handleRowOrderChange}  
              />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LicenceTable;
