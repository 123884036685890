import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Segment from "../../../../../components/Segment";
import {
  Button,
  Group,
  TextArea,
  Dropdown,
  DateSelect,
  Toggle,
  Input,
} from "../../../../../components/Forms";
import API from "../../../../../api";
import CommonFunctions from "../../../../../CommonFunctions";
import Loader from "../../../../../components/Loader";
import { PencilIcon, XIcon } from "@heroicons/react/solid";

export default function Edit({auth}) {
  const history = useHistory();
  const params = useParams();

  const [userList, setUserList] = useState([]);
  const [data, setData] = useState({
    description: "",
    assigned: "",
    due_date: "",
    time: 0,
    currentRate: 0,
    isBillable: true,
    defaultAdminRate: false,
    rateDetails: "",
  });
  const [defaultAdminRate, setDefaultAdminRate] = useState(0);

  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => { 
    if (mountLoading) {
      API.matters.get(params.matter_id).then((res) => {

        let scale = res.scale;
        let fee_thresholds = res.fee_thresholds;

        API.matters.debtors.activities
          .get(params.matter_id, params.debtor_id, params.activity_id).then(res2 => {
              setData(res2);

              API.users
                .getAll()
                .then((res3) => {
                  setUserList(
                    res3.users.map((a) => {
                      let ft = fee_thresholds.filter(x => x.user == a._clioId);

                      let rate = a.rate;
                      let rateDetails = "Using User Settings Rate";

                      if(scale === "hourlyScale" && ft && ft[0]){
                        rate = parseFloat(ft[0].fee);
                        rateDetails = "Using Matter Hourly Fee Rate";
                      }

                      if(auth && a.email == auth.user.email){
                        setData({ ...data, assigned: a._id, currentRate: rate, rateDetails });
                      }else{
                        setData({ ...data, rateDetails });
                      }
                      return {
                        text: a.name,
                        value: a._id,
                        rate,
                        rateDetails
                      };
                    })
                  );


                  API.settings.get().then(res4 => setDefaultAdminRate(res4.adminRate)).catch((e) => CommonFunctions.handleError(e))

                  setMountLoading(false); 

                })
                .catch((e) => CommonFunctions.handleError(e));  

          }).catch((e2) => CommonFunctions.handleError(e2));    

      })
      
    }
  }, [params, mountLoading]);

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onDateChange = (date) => {
    setData({
      ...data,
      due_date: date,
    });
  };

  return (
    <div className="mt-4">
      <Segment>
        <div className="">
          <h3 className="text-xl leading-6 font-medium text-gray-900">
            Edit Activity
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
          {mountLoading ? (
            <Loader />
          ) : (
            <>
              <Group className="py-2">
                <TextArea
                  label="Description"
                  name="description"
                  value={data.description}
                  onChange={onChange}
                />
              </Group>
              <Group className="py-2">
                <Dropdown
                  label="Fee Earner"
                  name="assigned"
                  value={data.assigned}
                  onChange={(e) => {
                    const user = userList.find(
                      (item) => item.value === e.target.value
                    );

                    setData({
                      ...data,
                      [e.target.name]: e.target.value,
                      currentRate:
                        data.defaultAdminRate !== true
                          ? user
                            ? user.rate
                            : 0
                          : data.currentRate,
                    });
                  }}
                  options={userList}
                />
                <DateSelect
                  label="Due Date"
                  name="due_date"
                  value={data.due_date}
                  onChange={onDateChange}
                />
                <Input
                  type="number"
                  incrementBy="6"
                  label="Time"
                  name="time"
                  value={data.time}
                  onChange={onChange}
                />
                <div>
                  <Input
                    name="cost"
                    label="Cost (£) (time * (rate / 60))"
                    disabled
                    value={(
                      data.time * (data.currentRate ? data.currentRate / 60 : 0)
                    ).toFixed(2)}
                  />
                  <p>{data.rateDetails} @ £{data.currentRate} p/h</p>

                </div>
              </Group>
              <Group>
                <Toggle
                  className="my-2"
                  label="Charge Default Admin Rate"
                  enabled={
                    data.defaultAdminRate ? data.defaultAdminRate : false
                  }
                  onChange={() => {
                    const user = userList.find(
                      (item) => item.value === data.assigned
                    );

                    let rate = 0;

                    if(data.defaultAdminRate){
                      rate = user.rate
                    }else{
                      rate = defaultAdminRate
                    }

                    setData({
                      ...data,
                      defaultAdminRate: !data.defaultAdminRate,
                      currentRate: rate,
                    });
                  }}
                />
                <Toggle
                  className="my-2"
                  label="Is Billable?"
                  enabled={data.isBillable ? data.isBillable : false}
                  onChange={() =>
                    setData({
                      ...data,
                      isBillable: data.isBillable ? !data.isBillable : true,
                    })
                  }
                />
              </Group>

              <div className="pt-2 flex justify-between">
                <Button
                  colour="alert"
                  label="Cancel"
                  onClick={() =>
                    history.push(
                      `/matters/${params.matter_id}/debtors/${params.debtor_id}/activities/${params.activity_id}`
                    )
                  }
                  icon={XIcon}
                />
                <Button
                  colour="positive"
                  label="Update"
                  onClick={() => {
                    API.matters.debtors.activities
                      .update(
                        params.matter_id,
                        params.debtor_id,
                        params.activity_id,
                        data
                      )
                      .then(() =>
                        history.push(
                          `/matters/${params.matter_id}/debtors/${params.debtor_id}/activities/${params.activity_id}`
                        )
                      )
                      .catch((e) => CommonFunctions.handleError(e));
                  }}
                  icon={PencilIcon}
                />
              </div>
            </>
          )}
        </div>
      </Segment>
    </div>
  );
}
