import React from "react";
import dayjs from "dayjs";
import { DataGridPro, GridRowsProp, GridColDef } from "@mui/x-data-grid-pro";
import CommonFunctions from "../../../../CommonFunctions";
import { Button } from "../../../../components/Forms";
import { GiMagnifyingGlass, GiPencil } from "react-icons/gi";
import { LinkIcon } from "@heroicons/react/solid";

export default function FeeSchedule({ matter, debts, history, onLink }) {
  let amount = 0;
  let total_amount_payments = 0;
  let total_amount = 0;
  let total_paid = 0;
  let total_vat = 0;

  for (let index = 0; index < debts.length; index++) {
    const debt = debts[index];
    if (debt.amount) {
      total_amount = total_amount + debt.amount;
      total_vat = total_vat + (debt.amount * 0.2); //grab from org settings
      total_paid = total_paid + debt.amountPaid;
      // amount = amount + ((debt.amount + debt.amount * 0.2) - (debt.amountPaid ? debt.amountPaid : 0));
      amount = amount + (debt.amount - (debt.amountPaid ? debt.amountPaid : 0)); // VAT is already included
    }

  }
  let total = amount;

  let arr = [];

  debts.map((d, i) => {
    d.transactions.map((t,ind) => {
      console.log("🚀 ~ d.transactions.map ~ t:", d)
      total_amount_payments = total_amount_payments + (parseFloat(t.credit) ? parseFloat(t.credit) : 0);

      arr.push({
        key: `transaction_${i}`,
        ...t,
        id: t._id, 
        fee: d.reference,
        issueDate: dayjs(t.issueDate).isValid() ? dayjs(t.issueDate).format("DD/MM/YYYY") : "-",
        dueDate: dayjs(t.dueDate).isValid() ? dayjs(t.dueDate).format("DD/MM/YYYY") : "-",
        paidDate: dayjs(t.paidDate).isValid() ? dayjs(t.paidDate).format("DD/MM/YYYY") : "-",
        amount: `£${parseFloat(t.amount) ? CommonFunctions.toFormattedFixed(parseFloat(t.amount)) : 0.00}`,
        amountVAT: `£${parseFloat(t.amountVAT) ? CommonFunctions.toFormattedFixed(parseFloat(t.amountVAT)) : 0.00}`,
        // due: dayjs(t.dateDue).isValid() ? dayjs(t.dateDue).format("DD/MM/YYYY") : "-",
        // credit: `£${parseFloat(t.credit) ? CommonFunctions.toFormattedFixed(parseFloat(t.credit)) : 0.00}`,
      });
    });

  });

  // const rows: GridRowsProp = arr.length > 0 ? arr.sort((a,b) => a.createdAt < b.createdAt ? 1 : -1) : [];
  const rows: GridRowsProp = arr.length > 0 ? arr.sort((a,b) => a.paidDate > b.paidDate ? 1 : -1) : [];

  /*

  •	Payment Type                   eg Invoice, Sales Credit
  •	Number
  •	Reference 
  •	Issue Date
  •	Amount Due
  •	VAT 
  •	Total Amount Due
  •	Due Date
  •	Amount Paid
  •	Paid Date
  •	Status                                  eg Paid, Awaiting

  */

  const columns: GridColDef[] = [
    { headerName: "Fee", field: "fee", width: 300, pinnable: false },
    { headerName: "Payment Type", field: "paymentType", width: 200, pinnable: false },
    { headerName: "Number", field: "number", width: 200, pinnable: false },
    { headerName: "Fee Type", field: "reference", width: 250, pinnable: false }, 
    { headerName: "Issue Date", field: "issueDate", width: 150, pinnable: false }, 
    { headerName: "Total Amount Paid", field: "amount", width: 150, pinnable: false }, 
    { headerName: "VAT", field: "amountVAT", width: 150, pinnable: false }, 
    // { headerName: "Total Amount Due", field: "totalDue", width: 150, pinnable: false }, 
    { headerName: "Due Date", field: "dueDate", width: 150, pinnable: false }, 
    // { headerName: "Amount Paid", field: "amountPaid", width: 150, pinnable: false }, 
    { headerName: "Paid Date", field: "paidDate", width: 150, pinnable: false }, 
    // { headerName: "Status", field: "status", width: 150, pinnable: false }, 
  ];

  const columnsWithFooter = columns.map((column) => {
    if (column.field === 'credit') {
      return {
        ...column,
        footerName: 'Total',
        footerRenderer: () => total_amount_payments,
      };
    }
    return column;
  });
  
  /*

  •	Add column before Date for “Invoice Number”
  •	Add column after above for “Reference”
  •	Change “Date” to “Issue Date”
  •	Change “Days” to “Days Not Paid”
  •	Change “Invoice” to “XERO Invoice No”
  •	Change “Amount” to “Amount Due”
  •	Add “VAT @ 20%”
  •	Add “Total Amount Due”
  •	Add “Due Date”
  •	Remove “Interest”, “Compensation” & “Legal Costs” as not needed for this
  •	Add “Amount Paid”
  •	Add “Paid Date”
  •	Change “Balance” to “Fee Balance” 


  */

  return (
    <>
      <table className=" w-full  border border-gray-400">
        <thead>
          <tr className="bg-gray-200 text-gray-600 divide-x divide-gray-300">
            <th className="font-medium  ">&nbsp;</th>
            <th className="font-medium  ">PLF Invoice Number</th>
            <th className="font-medium  ">Fee Type</th>
            <th className="font-medium  ">Issue Date</th>
            <th className="font-medium  ">Days Not Paid</th>
            <th className="font-medium ">XERO Invoice No</th>
            <th className="font-medium ">XERO Status</th>
            {/* <th className="font-medium ">Debtors</th> */}
            <th className="font-medium  ">Amount Due</th>
            <th className="font-medium  ">Fee Amount</th>
            <th className="font-medium  ">Amount VAT</th>
            <th className="font-medium  ">Due Date</th>
            <th className="font-medium  ">Amount Paid</th>
            <th className="font-medium  ">Paid Date</th>
            <th className="font-medium  ">Status</th>
            <th className="w-1/6 font-medium ">Fee Balance </th>
            <th className="font-medium ">&nbsp;</th>
          </tr>
        </thead>
        <tbody className="p-2 divide-y divide-gray-300 ">
          {debts.map((d, i) => {
            console.log("🚀 ~ {debts.map ~ d:", d)
            let lineTotal = 0;
            if (d.amount) {
              // lineTotal = parseFloat(d.amount) + parseFloat(d.amount * 0.2) 
              lineTotal = parseFloat(d.amount) //already have VAT
            }
            lineTotal = lineTotal - (d.amountPaid ? d.amountPaid : 0);
            
            return (
              <tr
                className="p-2 divide-x divide-gray-300 hover:bg-gray-50"
                key={`fee_`+i}
              >
                <td className="p-2">
                  {!d.xeroInvoiceNumber && !d.linkedFeeParent && matter.xero_send !== "instant" ?
                  <input
                      id={"link_"+i}
                      name={"link_"+i}
                      type="checkbox"
                      onChange={() => {
                        onLink(i);
                      }}
                      
                      checked={d.link}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  /> : d.linkedFeeParent ? <><LinkIcon className="w-7 text-action" /> {d.linkedFeeParent.reference}</> : ""}
                </td>
                <td className="p-2">{d.invoice_num}</td>
                <td className="p-2">{d.reference}</td>
                <td className="p-2">{d.issueDate ? dayjs(d.issueDate).format("DD/MM/YYYY") : "--"}</td>
                <td className="p-2">{d.issueDate && d.status !== "paid" ? dayjs().diff(dayjs(d.issueDate), 'day') : "--"}</td>
                <td className="  p-2">{d.linkedFeeParent ? d.linkedFeeParent.xeroInvoiceNumber : d.xeroInvoiceNumber}
                
                <Button 
                  // disabled={!d.xeroInvoiceID}
                  // disabled={!d.xeroInvoiceURL}
                  disabled={!d.xeroInvoiceURL}
                  className="ml-3"
                  style={{display: "inline-block"}}
                  icon={GiMagnifyingGlass}
                  // onClick={() =>  window.open("https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID="+d.xeroInvoiceID, '_blank').focus()}
                  onClick={() =>  window.open(d.xeroInvoiceURL, '_blank').focus()}
                  colour={"action"} />
                
                </td>
                <td className=" text-right p-2">
                  {d.xeroInvoiceNumber ? d.xeroStatus : ""}
                </td>
                <td className=" text-right p-2">
                  £{d.amount ? CommonFunctions.toFormattedFixed(d.amount) : "0.00"}
                </td>
                <td className=" text-right p-2">
                  {/* £{d.amount ? CommonFunctions.toFormattedFixed(parseFloat(d.amount) + parseFloat(d.amount * 0.2)) : "0.00"} */}
                  £{d.amount ? CommonFunctions.toFormattedFixed(parseFloat(d.amount) - parseFloat(d.amount * 0.2)) : "0.00"} {/* Minus VAT not add */}
                </td>
                <td className=" text-right p-2">
                  £{d.amount ? CommonFunctions.toFormattedFixed(d.amount * 0.2) : "0.00"}
                </td>
                <td className="p-2">{d.dueDate ? dayjs(d.dueDate).format("DD/MM/YYYY") : "--"}</td>
                <td className=" text-right p-2">
                  £{d.amountPaid ? CommonFunctions.toFormattedFixed(d.amountPaid) : "0.00"}
                </td>
                <td className="p-2">{d.paidDate ? dayjs(d.paidDate).format("DD/MM/YYYY") : "--"}</td>
                <td className="p-2">{d.status}</td>
                <td className=" text-right p-2 ">£{lineTotal && CommonFunctions.toFormattedFixed(lineTotal)}</td>
                <td>
                  <Button 
                    icon={GiPencil}
                    colour={"action"}
                    onClick={() =>
                      history.push(
                        `/matters/${d.matter}/fees/${d._id}`
                      )
                    }
                  /></td>
              </tr>
            );
          })}
          <tr className="p-2 divide-x divide-gray-300 bg-gray-200">
            <td colSpan={7}>&nbsp;</td>
            <td className="text-right p-2 border-r border-gray-400">
              Total Due: £{CommonFunctions.toFormattedFixed(total_amount)}
            </td>
            <td className="text-right p-2 border-r border-gray-400">
              Total Fee: £{CommonFunctions.toFormattedFixed(total_amount - total_vat)}
            </td>
            <td className="text-right p-2 border-r border-gray-400">
              Total VAT: £{CommonFunctions.toFormattedFixed(total_vat)}
            </td>
            <td>&nbsp;</td>
            <td className="text-right p-2 border-r border-gray-400">
              Total Paid: £{CommonFunctions.toFormattedFixed(total_paid)}
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td className="w-1/6 p-2 text-right">Total Balance: £{CommonFunctions.toFormattedFixed(total)}</td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <h2 className="mt-5">Payments:</h2>

      <DataGridPro rows={rows} columns={columns} initialState={{ pinnedColumns: { left: ['uuid'], right: ['due'] } }} />
    </>
    
  );
}
