import { ApiRequest } from "../../plugins/clio_auth";
import AppConfig from "../../_appConfig";

export default class correspondents {
  async add(data) {
    return ApiRequest.post(
      `${AppConfig.api_server}/api/settings/correspondents/`,
      data
    );
  }

  async edit(id, data) {
    return ApiRequest.patch(
      `${AppConfig.api_server}/api/settings/correspondents/${id}`,
      data
    );
  }

  /*
  async remove(id) {
    return ApiRequest.delete(
      `${AppConfig.api_server}/api/settings/correspondents/${id}`
    );
  }*/

  async list(org) {
    return ApiRequest.get(
      `${AppConfig.api_server}/api/settings/correspondents/list${org ? `?org_id=${org}` : ''}`
    );
  }
  async get(id) {
    return ApiRequest.get(
      `${AppConfig.api_server}/api/settings/correspondents/${id}`
    );
  }
}
