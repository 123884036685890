import React, { useState, useEffect } from "react";
import { ThemeTable } from "../../../components/Tables";
import { Button } from "../../../components/Forms";
import API from "../../../api";
import Segment from "../../../components/Segment";
import CommonFunctions from "../../../CommonFunctions";
import Loader from "../../../components/Loader";
import { PlusIcon } from "@heroicons/react/solid";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import ChainedContext from "../../../contexts/chainedContext";
import MatterBar from "../_components/MatterBar";
import MatterBreadcrumbs from "../_components/MatterBreadcrumbs";
function DebtorList({ matter, history, match }) {
  const [matterDebtors, setMatterDebtors] = useState({});
  const [matterFees, setMatterFees] = useState([]);
  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    API.matters.debtors
      .getAll(matter._id)
      .then((res) => {
        setMatterDebtors(res);
        setMountLoading(false);
      })
      .catch((e) => CommonFunctions.handleError(e));
  }, [matter]);

  useEffect(() => {
    API.matters.fees
      .getAll(matter._id)
      .then((res) => {
        setMatterFees(res);
        setMountLoading(false);
      })
      .catch((e) => CommonFunctions.handleError(e));
  }, [matter]);

  return (
    <div className="">
      <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.type == "Company" ? `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}` : `${matter.contact.prefix} ${matter.contact.first_name} ${matter.contact.last_name} `} `}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number} ${matterDebtors.debtors ? `(${matterDebtors.debtors.map((d, i) => (i !== 0 ? " " : "") + d.debtor_name)})` : ""}`, href: `/matters/${matter._id}`, current: false },
        { name: "Debtors", href: '#', current: true },
      ]} />
      <Segment>
        <MatterBar matter={matter} history={history} />
        <div className="">
          <div className=" leading-6 font-medium text-gray-900 flex justify-between">
            <span className="text-2xl my-auto">Debtors</span>
            <Button
              colour="positive"
              label="Add Debtor"
              onClick={() => history.push(`${match.url}/add`)}
              className="ml-auto"
              icon={PlusIcon}
            />
          </div>
          <div className="w-full border-t border-gray-300 my-2" />
        </div>
        {mountLoading ? (
          <Loader />
        ) : (
          <>
            <div className="md:grid md:grid-cols-3 md:gap-36 pt-3 pb-4">
              <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                <div className="text-3xl">{matterDebtors.debtors && matterDebtors.debtors.length}</div>
                <div className="text-xl text-primary">Debtors</div>
              </div>
              <div className="border border-primary p-4 rounded-xl text-center md:mx-auto md:w-52">
                <div className="text-3xl">
                  £{CommonFunctions.toFormattedFixed(matterDebtors.totalOwed)}
                </div>
                <div className="text-xl text-primary">Debt Balance O/S</div>
              </div>
              <div className="border border-primary p-4 rounded-xl text-center md:mx-auto md:w-52">
                <div className="text-3xl">
                  £{CommonFunctions.toFormattedFixed(matterFees.reduce((acc, fee) => {
                    return acc + (fee.amount + (fee.amount * 0.2) + fee.feeAmount - fee.amountPaid); //grab from org settings
                  }, 0))}
                </div>
                <div className="text-xl text-primary">Fee Balance O/S</div>
              </div>
            </div>
            <ThemeTable
              header={[
                { text: "Debtor Name", value: "debtor_name" },
                { text: "Total Debt", value: "total_debt" },
                // { text: "Company Type", value: "company_type" },
                { text: "Balance Outstanding", value: "amount_owed" },
                // { text: "Stage", value: "stage" },
              ]}
              body={matterDebtors.debtors && matterDebtors.debtors.map((debt, index) => {
                let balance = matterDebtors.totalOwed;
                let totalDebt = matterFees.reduce((acc, fee) => {
                  return acc + (fee.amount + (fee.amount * 0.2) + fee.feeAmount - fee.amountPaid);
                }, 0)
                
                return {
                  ...debt,
                  amount_owed: `£${debt.amount_owed && (CommonFunctions.toFormattedFixed(debt.amount_owed))}`,
                  total_debt: index > 0 ? "n/a" : `£${CommonFunctions.toFormattedFixed(parseFloat(balance) + parseFloat(totalDebt))}`
                }
              }
            
            
            )}
              action={(item, index) =>
                history.push("/matters/" + matter._id + "/debtors/" + item._id)
              }
              noDataMessage="No Debtors..."
            />
          </>
        )}
      </Segment>
    </div>
  );
}
export default ChainedContext(DebtorList, [
  [
    MatterReceiver,
    (matter) => {
      return { matter };
    },
  ],
]);
