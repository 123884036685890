import React, { useState, useEffect, Fragment } from "react";
import { toast } from "react-hot-toast";
import Segment from "../../../../components/Segment";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';

import ChainedContext from "../../../../contexts/chainedContext";
import MatterReceiver from "../../../../contexts/matter_context/Matter_Receiver";
import DebtorReceiver from "../../../../contexts/debtor_context/Debtor_Receiver";
import DebtorBar from "../_components/DebtorBar";
import API from "../../../../api";
import DatePicker from "react-datepicker";
import { Button, DateSelect, Input, SelectTwo } from "../../../../components/Forms";
import CommonFunctions from "../../../../CommonFunctions";
import { useParams } from "react-router-dom";
import DocumentsListView from "../_components/documentsListView";
import { TrashIcon, XIcon } from "@heroicons/react/solid";
import { Dialog, Transition } from "@headlessui/react";
import TransactionImporter from "./_components/TransactionImporter";
import Loader from "../../../../components/Loader";

function Debt_Dash({ history, debtor, matter, match }) {

  dayjs.extend(customParseFormat);

  const params = useParams();
  const [debt, setDebt] = useState({
    Transactions: [],
  });

  const [currentTab, setCurrentTab] = useState("overview");
  const [editTransaction, setEditTransaction] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState([]);

  const [data, setData] = useState({
    datePaid: new Date(),
    dateDue: new Date(),
    credit: 0,
    reference: null,
    paymentType: ""
  });

  const [debtInterest, setDebtInterest] = useState(0);
  const [debtInterestBreakdown, setDebtInterestBreakdown] = useState([]);
  const [importModal, setImportModal] = useState(false);

  const currentYear = (new Date()).getFullYear();
  const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

  const years = range(currentYear + 6, currentYear - 12, -1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    if (matter._id && matter.contact._id) {
      getDebt();
    }
  }, [matter]);

  const getDebt = () => {
    Promise.all([
      API.matters.debtors.debt
        .getOne(matter._id, debtor._id, match.params.debt_id)
        .catch((e) => CommonFunctions.handleError(e)),
      API.matters.debtors.debt
        .interest(matter._id, debtor._id, match.params.debt_id)
        .catch((e) => CommonFunctions.handleError(e)),
      API.settings.payment_types.get(),

      //rates
      API.settings.base_rates.normal.get(),
      API.settings.base_rates.compensation.get(),
      API.settings.base_rates.late_payments.get(),
      API.settings.base_rates.statutory.get()
    ]).then((res) => {
      setDebt(res[0]);
      setEditTransaction(null);

      setData({
        ...data,
      })

      // res[0]

      if (res[0] && res[1])
        setDebtInterest(
          CommonFunctions.calculateDebt(
            res[0].interest ? res[0].interest : 0,
            res[1]
          ).totalDebt
        );

      let normal_rates = res[3];
      let compensation_rates = res[4];
      let late_rates = res[5];
      let statutory_rates = res[6];

      setDebtInterestBreakdown(
        CommonFunctions.generateDailyDebtPayments(
          res[0].interest ? res[0].interest : 0,
          res[1], res[0], {
            normal_rates,
            compensation_rates,
            late_rates,
            statutory_rates
          }
        )
      );

      setPaymentTypes(res[2].filter(t => t.archived !== true).map((p) => {
        return { key: 0, text: p.title, value: p._id };
      }));
    });
  };

  const onChange = (e) => {

    if(e.target.name === "credit" && (parseFloat(e.target.value) > parseFloat(debt.Outstanding).toFixed(2))){
      toast.error(`£${e.target.value} can't be entered as this is above the total, debt total is £${parseFloat(debt.Outstanding) ? parseFloat(debt.Outstanding).toFixed(2) : debt.Outstanding}`)
        setData({ ...data, [e.target.name]: "" });
        return false;
    }

    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleAdd = () => {
    let formData = {};
    formData.credit = parseFloat(data.credit);
    formData.transactionDate = data.datePaid;
    formData.reference = data.reference;
    formData.dateDue = data.dateDue;
    formData.paymentType = data.paymentType;

    API.matters.debtors.debt
      .addTransaction(matter._id, debtor._id, debt._id, formData)
      .then((res) => {
        if(res.success){
          toast.success("Transaction Added");
          setData({ 
            datePaid: new Date(),
            dateDue: new Date(),
            paymentType: "",
            credit: 0,
            reference: ""
          });
          getDebt();
          setCurrentTab("overview");
        }else{
          toast.error(res.message);
        }
      });
  };
  const makeEdit = (d) => {
    setData({ ...data, ...d });
    setEditTransaction(d._id);
  };
  const handleEdit = () => {
    let formData = {};
    formData.credit = parseFloat(data.credit);
    formData.transactionDate = data.datePaid;
    formData.reference = data.reference;
    formData.dateDue = data.dateDue;
    formData.paymentType = data.paymentType;

    API.matters.debtors.debt
      .editTransaction(matter._id, debtor._id, debt._id, formData, editTransaction)
      .then((res) => {

        if(res.success){
          toast.success("Transaction edited");
          setData({ 
            datePaid: new Date(),
            dateDue: new Date(),
            paymentType: "",
            credit: 0,
            reference: ""
          })
          getDebt();
          setCurrentTab("overview");
        }else{
          toast.error(res.message);
        }
 
      });
  };

  const tabs = [
    { name: 'Debt Overview', tab: 'overview', current: currentTab === 'overview' },
    { name: 'Interest', tab: 'interest', current: currentTab === 'interest' },
    { name: 'Payments', tab: 'payments', current: currentTab === 'payments' },
  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const renderModal = () => {
    const handleClose = () => {
      setImportModal(false);
      getDebt();
    };

    return (
      <Transition.Root show={importModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={handleClose}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:py-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Import Payments from CSV
                    </Dialog.Title>
                    <div className="mt-4">
                      <div className={`overflow-x-auto text-lg`}>
                        <TransactionImporter 
                          title={"File"} 
                          isUploadingInProgress={a => setIsUploading(a)}
                          debtor={debtor}
                          matter={matter}
                          debt={debt}
                          onUploadedDocument={res => {
                            console.log("🚀 ~ <TransactionImporter", res);
                            // setIsUploading(false);
                            toast.success("Imported successfully");
                          }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  {/*  
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleModalSubmit}
                    disabled={isUploading}
                  >
                    Import
                  </button>*/}
                  <Button
                    label="Close"
                    colour="alert"
                    onClick={handleClose}
                    // disabled={isUploading}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <Segment>
      {renderModal()}
      <DebtorBar debtor={debtor} contact={matter.contact} />
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
          <h3>#{debt.invoice_num} | Amount Left: £{(parseFloat(debt.Outstanding) ? CommonFunctions.toFormattedFixed(parseFloat(debt.Outstanding)) : 0.00)}</h3>
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  onClick={() => setCurrentTab(tab.tab)}
                  className={classNames(
                    tab.current
                      ? 'border-primary text-primary'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className={`grid sm:grid-cols-${currentTab === 'overview' ? "2" : "1"} gap-4`}>
        {currentTab === 'overview' &&
          <>
            <Segment>
              <div className="p-2 text-xl text-center text-primary">
                The Debt Details

                <Button 
                  style={{display: "inline-block"}}
                  label={"Edit"}
                  onClick={() => history.push(`/matters/${params.matter_id}/debtors/${params.debtor_id}/debt/${params.debt_id}/edit`)}
                  colour={"action"} />

              </div>
              <div className="px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Debtors
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">{debt.debtor_names}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Initial Debt
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">£{debt.amount}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Invoice Number #
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {debt.invoice_num}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Compensation
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {debt.compensation}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Invoice Date
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {dayjs(debt.invoiceDate).format("DD-MM-YYYY")}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Interest Date
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {dayjs(debt.interestDate).format("DD-MM-YYYY")}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Number of Days
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {dayjs().diff(dayjs(debt.interestDate), "day")}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Rate Type</dt>
                    <dd className="mt-1 text-sm text-gray-900">{debt.rateType}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Interest</dt>
                    <dd className="mt-1 text-sm text-gray-900">{debt.interest}%</dd>
                  </div>
                </dl>
              </div>
            </Segment>
            <Segment>
              <div className="px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Amount Paid
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      £{parseFloat(debt.AmountPaid) ? CommonFunctions.toFormattedFixed(parseFloat(debt.AmountPaid)) : 0.00}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Amount Outstanding
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      £{(parseFloat(debt.Outstanding) ? CommonFunctions.toFormattedFixed(parseFloat(debt.Outstanding)) : 0.00)}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Interest Accrued
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">£{debtInterestBreakdown.length > 0 ? CommonFunctions.toFormattedFixed(debtInterestBreakdown.reduce((accumulator, currentObject) => parseFloat(accumulator) + parseFloat(currentObject.amount), 0)) : 0.00}</dd>
                    {/* <dd className="mt-1 text-sm text-gray-900">£{parseFloat(debtInterest) ? CommonFunctions.toFormattedFixed(parseFloat(debtInterest)) : 0.00}</dd> */}
                  </div>
                </dl>
              </div>

              <DocumentsListView files={debt.invoices} noDocumentsText={"There are no associated files uploaded"} />

            </Segment>
          </>
        }
        {currentTab === 'interest' &&
          <>
            <Segment>
              <div className="flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Date
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Payment
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Interest Rate
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Interest
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Interest Accrued
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {debtInterestBreakdown.map((item, index) => {
                            let transactions = debt.Transactions.filter(t => 
                              dayjs(t.transactionDate).startOf('day').isSame(dayjs(item.day, 'DD-MM-YYYY').startOf('day'))
                            )


                            let outstandingAmount = item.outstandingAmount && CommonFunctions.toFormattedFixed(parseFloat(item.outstandingAmount));
                            let interestPerDay = item.interestPerDay && CommonFunctions.toFormattedFixed(parseFloat(item.interestPerDay), 5);

                            return(
                              <tr key={index}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {item.day ? item.day : "--"}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {transactions.length > 0 ? transactions.map(t => (`#${t.reference}: £${t.credit && CommonFunctions.toFormattedFixed(parseFloat(t.credit))}`)).join(", ") : ""}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {item.interestRate} @ {" "}
                                  {debt.rateType == "simply" && "Simple Flat Rate of 3"} 
                                  {debt.rateType == "contractual" && "Contractual Rate Set"} 
                                  {debt.rateType == "statutory" && "Statutory Rate on "} 
                                  {debt.rateType == "monthly" && `(${debt.interest_monthly} per month)`} 
                                  {item.interestRateDate && dayjs(item.interestRateDate).format("DD-MM-YYYY")} {debt.rateType === "base_rate_plus" && debt.interest_plus && `(base of: ${item.base} + ${debt.interest_plus})`}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {outstandingAmount} * {interestPerDay} =
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  £{ item.amount && CommonFunctions.toFormattedFixed(parseFloat(item.amount))}
                                </td>
                              </tr>
                            )
                          
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Segment>
          </>
        }
        {currentTab === 'payments' &&
          <>
            <Segment>
              <div className="float-right">
                <Button 
                  label="Import"
                  colour="action"
                  onClick={() => setImportModal(true)}
                />
              </div>
              <div className="p-2 text-xl text-center text-primary">
                The Transaction List
              </div>
              <div className="flex flex-col">
                <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              ID
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Reference
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Date
                            </th>
                            {/*  
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Due Date
                            </th>*/}
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Amount
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Payment Method
                            </th>
                            <th>&nbsp;</th>
                          </tr>
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            ></th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <Input
                                name="reference"
                                onChange={onChange}
                                className="w-full"
                                value={data.reference}
                                error={!data.reference}
                              />
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              <DateSelect
                                onChange={(date) =>
                                  setData({
                                    ...data,
                                    datePaid: date,
                                  })
                                }
                                value={
                                  data.datePaid ? new Date(data.datePaid) : ""
                                }
                                name="datePaid"
                                className={`relative w-full p-2 `}
                              />
                            </th>
                            {/*  
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              <DatePicker
                                onChange={(date) =>
                                  setData({
                                    ...data,
                                    dateDue: date,
                                  })
                                }
                                selected={
                                  data.dateDue ? new Date(data.dateDue) : ""
                                }
                                showPopperArrow={false}
                                shouldCloseOnSelect={true}
                                dateFormat="dd-MM-yy"
                                peekNextMonth
                                className={`relative w-full  p-2 rounded-lg bg-gray-100 border `}
                              />
                            </th>*/}
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <Input
                                type="number"
                                name="credit"
                                onChange={onChange}
                                className="w-full"
                                value={data.credit}
                                error={!editTransaction && (!data.credit || !data.credit > 0 || (data.credit > debt.Outstanding))}
                              />
                            </th>
                            <th
                             scope="col"
                             className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                            <SelectTwo
                              placeholder="Select Method"
                              field="paymentType"
                              options={paymentTypes}
                              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              value={data.paymentType}
                              onChange={onChange}
                            />
                            </th>
                            <th>
                              <Button
                                disabled={!editTransaction && (!data.credit || !data.reference || !data.credit > 0 || (data.credit > debt.Outstanding))}
                                label={editTransaction ? "Edit" :"Add"}
                                colour={editTransaction ? "warning" :"positive"}
                                onClick={editTransaction ? handleEdit : handleAdd}
                              />
                              {editTransaction && data._id &&
                                <Button 
                                  icon={TrashIcon}
                                  color="alert"
                                  onClick={() => API.matters.debtors.debt.removeTransaction(matter._id, debtor._id, debt._id, data._id).then(res => {
                                    if(res.success){
                                      toast.success(res.message);
                                      setData({ 
                                        datePaid: new Date(),
                                        dateDue: new Date(),
                                        paymentType: "",
                                        credit: 0,
                                        reference: ""
                                      })
                                      getDebt();
                                      setCurrentTab("overview");
                                    }else{
                                      toast.error(res.message)
                                    }
                                  })}
                                />}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {debt.Transactions.sort((a,b) => a.createdAt < b.createdAt ? 1 : -1).map((d, i) => {
                            let paymentType = paymentTypes.filter(p => p.value === d.paymentType);
                            return (
                            <tr key={i}>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {d.uuid}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                #{d.reference}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {d.transactionDate
                                  ? dayjs(d.transactionDate).format("DD-MM-YYYY")
                                  : "--"}
                              </td>
                              {/*  
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {d.dateDue
                                  ? dayjs(d.dateDue).format("DD-MM-YYYY")
                                  : "--"}
                              </td>*/}
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                £{parseFloat(d.credit) ? CommonFunctions.toFormattedFixed(parseFloat(d.credit)) : 0.00}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {(paymentType && paymentType[0]) ? paymentType[0].text : "-"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {data._id !== d._id ?
                              <Button
                                label="Edit"
                                colour="warning"
                                onClick={() => makeEdit(d)}
                              /> : 
                              <Button 
                                colour="alert" 
                                icon={XIcon}
                                onClick={() => {
                                  setData({ 
                                    datePaid: new Date(),
                                    dateDue: new Date(),
                                    credit: 0,
                                    paymentType: "",
                                    reference: debt.Transactions ? debt.Transactions.length + 1 : null
                                  })
                                  getDebt();
                                }} />}
                              </td>
                            </tr>
                          )})}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Segment>
          </>
        }
      </div>
    </Segment>
  );
}
export default ChainedContext(Debt_Dash, [
  [MatterReceiver, "matter"],
  [DebtorReceiver, "debtor"],
]);
