import React, { useState, useEffect } from "react";
import { useHistory, useParams, withRouter} from "react-router-dom";
import Segment from "../../../../components/Segment";
import {
  Button,
  Group,
  TextArea,
  Dropdown,
  DateSelect,
  Toggle,
  Input,
} from "../../../../components/Forms";
import API from "../../../../api";
import CommonFunctions from "../../../../CommonFunctions";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import MatterBreadcrumbs from "../../_components/MatterBreadcrumbs";

import ChainedContext from "../../../../contexts/chainedContext";
import AuthenticationReceiver from "../../../../plugins/clio_auth/_components/authenticationReceiver";

function Add({auth}) {
  const history = useHistory();
  const params = useParams();

  const [defaultAdminRate, setDefaultAdminRate] = useState(0);

  const [userList, setUserList] = useState([]);
  const [matterDebtors, setMatterDebtors] = useState([]);
  const [matter, setMatter] = useState(null);
  const [data, setData] = useState({
    description: "",
    assigned: "",
    due_date: "",
    time: 1,
    isBillable: true,
    additional_task: true,
    currentRate: 0,
    defaultAdminRate: false,
    rateDetails: "",
  });

  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => { 
    if (mountLoading) {
      API.matters.get(params.matter_id).then((res) => {

        let scale = res.scale;
        let fee_thresholds = res.fee_thresholds;

        setMatter(res);

        API.matters.debtors
          .getAll(params.matter_id)
          .then((res2) => {
            setMatterDebtors(res2);

            API.users
              .getAll()
              .then((res3) => {
                setUserList(
                  res3.users.map((a) => {
                    let ft = fee_thresholds.filter(x => x.user == a._clioId);

                    let rate = a.rate;
                    let rateDetails = "Using User Settings Rate";

                    if(scale === "hourlyScale" && ft && ft[0]){
                      rate = parseFloat(ft[0].fee);
                      rateDetails = "Using Matter Hourly Fee Rate";
                    }

                    if(auth && a.email == auth.user.email){
                      setData({ ...data, assigned: a._id, currentRate: rate, rateDetails });
                    }else{
                      setData({ ...data, rateDetails });
                    }
                    return {
                      text: a.name,
                      value: a._id,
                      rate,
                      rateDetails
                    };
                  })
                );


                API.settings.get().then(res4 => setDefaultAdminRate(res4.adminRate)).catch((e) => CommonFunctions.handleError(e))

                setMountLoading(false); 

              })
              .catch((e) => CommonFunctions.handleError(e));  

          })
        .catch((e2) => CommonFunctions.handleError(e2));
    
      })
      
    }
  }, [mountLoading]);

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onDateChange = (date) => {
    setData({
      ...data,
      due_date: date,
    });
  };

  return (
    <div>
      {matter &&
      <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.type == "Company" ? `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}` : `${matter.contact.prefix} ${matter.contact.first_name} ${matter.contact.last_name} `} `}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number} ${matterDebtors.debtors ? `(${matterDebtors.debtors.map((d, i) => (i !== 0 ? " " : "") + d.debtor_name)})` : ""}`, href: `/matters/${matter._id}`, current: false },
        { name: "Tasks", href: `/matters/${matter._id}/tasks`, current: false },
        { name: `Add Manual Task`, href: '#', current: true },
      ]} />}
      <Segment className="mt-4">
        <div className="">
          <h3 className="text-xl leading-6 font-medium text-gray-900">
            Create Manual Task
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
          <Group className="py-2">
            <TextArea
              label="Description"
              name="description"
              value={data.description}
              onChange={onChange}
            />
          </Group>
          <Group className="py-2">
            <Input
              type="number"
              incrementBy="6"
              label="Time"
              name="time"
              value={data.time}
              onChange={onChange}
            />
            <Dropdown
              label="Fee Earner"
              name="assigned"
              value={data.assigned}
              onChange={(e) => {
                const user = userList.find(
                  (item) => item.value === e.target.value
                );
                setData({ ...data, [e.target.name]: e.target.value, currentRate: !data.defaultAdminRate ? user.rate : data.currentRate });
              }}
              options={userList}
            />
            <DateSelect
              label="Due Date / Completed Date"
              name="due_date"
              value={data.due_date}
              onChange={onDateChange}
            />
            <div>

              <Input
                name="cost"
                label="Cost (£) (time * (rate / 60))"
                disabled
                value={(
                  data.time * (data.currentRate ? data.currentRate / 60 : 0)
                ).toFixed(2)}
              />
              <p>{data.rateDetails} @ £{data.currentRate} p/h</p>

            </div>
            
          </Group>
          <Toggle
            className="my-2"
            label="Is Billable?"
            enabled={data.isBillable}
            onChange={() =>
              setData({
                ...data,
                isBillable: data.isBillable ? !data.isBillable : true,
              })
            }
          />
          <Toggle
            className="my-2"
            label="Charge Default Admin Rate"
            enabled={data.defaultAdminRate}
            onChange={() =>
              {
                const user = userList.find(
                  (item) => item.value === data.assigned
                );

                let rate = 0;
                let rateDetails = "";

                if(data.defaultAdminRate){
                  rate = user.rate
                  rateDetails = user.rateDetails;
                }else{
                  rate = defaultAdminRate;
                  rateDetails = "Default Admin Rate";
                }

                setData({
                  ...data,
                  defaultAdminRate: !data.defaultAdminRate,
                  currentRate: rate,
                });
              }
            }
          />
          
          <div className="pt-2 flex justify-between">
            <Button
              colour="alert"
              label="Cancel"
              onClick={() => history.push(`/matters/${params.matter_id}/tasks`)}
              icon={XIcon}
            />
            <Button
              colour="positive"
              label="Create"
              onClick={() => {
                API.matters.tasks
                  .create(params.matter_id, data)
                  .then((res) =>
                    history.push(`/matters/${params.matter_id}/tasks/${res}`)
                  );
              }}
              icon={CheckIcon}
            />
          </div>
        </div>
      </Segment>
    </div>
  );
}

export default ChainedContext(withRouter(Add), [
  [
    AuthenticationReceiver,
    (auth) => {
      return { auth };
    },
  ],
]);