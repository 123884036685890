import React, {useEffect, useState} from "react";
import Segment from "../../components/Segment";
import API from "../../api";
import dayjs from "dayjs";
import {Button, Input} from "../../components/Forms";
import {
  UserIcon,
  PhoneIcon,
} from "@heroicons/react/solid";
import {GiHouse} from "react-icons/gi";
import useQueryParams from "../../hooks/useQueryParams"
import { GoFilePdf } from "react-icons/go";
import { BiAlarm, BiCalendarExclamation, BiUser } from "react-icons/bi";
import toast from "react-hot-toast";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Account({history}) {
  const [mount, setMount] = useState(true);
  const [org, setOrg] = useState({});
  const [tab, setCurrentTab] = useState("active");

  let query = useQueryParams();

  useEffect(() => {
    API.organisations.getMyOrg().then((organisation) => setOrg(organisation)).finally(() => setMount(false));
    getData();
  }, []);

  function getData() {
    /*
     API.licences.getAll().then(res => {

     let price = 0;
     let seats = 0;

     let arr = res.map((l, index) => {

     price += parseFloat(l.price) || 0;
     seats += parseInt(l.seats, 10) || 0;

     return ({
     key: index,
     value: l._id,
     text: l.description,
     price: l.price,
     seats: l.seats
     })
     });

     setTotals({
     price, seats
     });
     setLicenceTypes(arr)

     }

     )*/
  }
  const goToBillingPortal = () => {
    API.billing.BillingPortalLink().then(res => {
      window.location.href = res.link;
    }).catch(err => {
      toast.error("Unable to retrieve billing portal link at this time, please try again later.");
    });
  }

  if (mount) {
    return "Loading..";
  }

  const adminlicencesExpiringSoon = org.users.filter(u => {
    if (u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user === "admin") {
      let days = u.licence_transaction.expiry ? dayjs(u.licence_transaction.expiry).diff(dayjs(), 'day') : 0;
      return days !== null && days <= 28; //16 days
    }
    return false;
  });
  const adminlicencesExpired = org.users.filter(u => {
    if (u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user === "admin") {
      let days = u.licence_transaction.expiry ? dayjs(u.licence_transaction.expiry).diff(dayjs(), 'day') : 0;
      return days !== null && days <= 0;
    }
    return false;
  });
  const userlicencesExpiringSoon = org.users.filter(u => {
    if (u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user === "user") {
      let days = u.licence_transaction.expiry ? dayjs(u.licence_transaction.expiry).diff(dayjs(), 'day') : 0;
      return days !== null && days <= 28; //16 days
    }
    return false;
  });
  const userlicencesExpired = org.users.filter(u => {
    if (u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user === "user") {
      let days = u.licence_transaction.expiry ? dayjs(u.licence_transaction.expiry).diff(dayjs(), 'day') : 0;
      return days !== null && days <= 0;
    }
    return false;
  });

  const tabs = [
    { name: 'Active', tab: "active", icon: BiUser },
    { name: 'Expiring (28 days)', tab: "expiring", icon: BiCalendarExclamation },
    { name: 'Expired', tab: "expired", icon: BiAlarm},
  ]

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
        <Segment>
          <main className="">
            {/* Page header */}
            <div className="mb-2 mx-auto px-4 md:flex md:items-center md:justify-between md:space-x-5 lg:px-4 border-b pb-2">
              <div className="flex items-center space-x-5">
                <div>
                  <h1 className="text-2xl font-bold text-primary">{org.name}</h1>
                  <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <GiHouse
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {org.addresses &&
                      org.addresses.find((item) => item.primary === true)
                        ? `${
                          org.addresses.find((item) => item.primary === true)
                            .postal_code
                        } (${
                          org.addresses.find((item) => item.primary === true).name
                        })`
                        : "N/A"}
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <PhoneIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {org.contactNumber}
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <UserIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {org.contact}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                <Button
                  onClick={() => history.push(`/settings/account/${org._id}/edit`)}
                  label="Edit Organisation"
                  colour="action"
                />
              </div>
            </div>
            {/* Main Content */}
            <div className={"px-5"}>
              {query.has("payment") && query.get("payment") === "cancelled" && <div className={"p-2 border rounded border-red-700 bg-red-300"}>Payment transaction has been cancelled.</div>}
              {query.has("payment") && query.get("payment") === "success" && <div className={"p-2 border rounded border-green-700 bg-green-300"}>Payment taken successfully, it might take a few minutes before the licences are available to be assigned.</div>}
            </div>
            <div className="mt-4 mx-auto md:grid md:grid-cols-3">
              <div className="col">
                <div className="md:grid md:grid-cols-2">
                  <div className="p-6 col-span-2 flex flex-col gap-4">
                    <div className="border border-primary bg-white rounded-lg px-4 pb-2">
                      <div className="text-center text-xl py-2 text-primary">
                        Users
                      </div>
                      <div className="flex justify-around mb-4">
                        <div className=" p-4 rounded-xl text-center">
                          <div className="text-3xl">
                            {org.users && org.users.length}
                          </div>
                          <div className="text-xl text-primary">No. of Users</div>
                        </div>
                        <div className=" p-4 rounded-xl text-center">
                          <div className="text-3xl">
                            {org.licenced} / {org.licence_total_seats}
                          </div>
                          <div className="text-xl text-primary">Licenced Users / Total Licences</div>
                        </div>
                      </div>

                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                          <th>Type</th>
                          <th>Purchased</th>
                          <th>Assigned</th>
                          <th>Remaining</th>
                          <th>Expiring Soon (28 days)</th>
                          <th>Expired</th>
                        </tr>
                        </thead>
                        <tbody className="text-center">
                        <tr>
                          <td>Admin</td>
                          <td>{org.transactions.filter(l => l.subscription && l.subscription.user == "admin").length}</td>
                          <td>{org.transactions.filter(l => l.subscription && l.subscription.user == "admin" && l.assigned_to).length}</td>
                          <td>{org.transactions.filter(l => l.subscription && l.subscription.user == "admin").length - org.transactions.filter(l => l.subscription && l.subscription.user == "admin" && l.assigned_to).length}</td>
                          <td>{adminlicencesExpiringSoon.length}</td>
                          <td>{adminlicencesExpired.length}</td>
                        </tr>
                        <tr>
                          <td>User</td>
                          <td>{org.transactions.filter(l => l.subscription && l.subscription.user == "user").length}</td>
                          <td>{org.transactions.filter(l => l.subscription && l.subscription.user == "user" && l.assigned_to).length}</td>
                          <td>{org.transactions.filter(l => l.subscription && l.subscription.user == "user").length - org.transactions.filter(l => l.subscription && l.subscription.user == "user" && l.assigned_to).length}</td>
                          <td>{userlicencesExpiringSoon.length}</td>
                          <td>{userlicencesExpired.length}</td>
                        </tr>
                        </tbody>
                      </table>

                    </div>

                    <div className="border border-primary bg-white rounded-lg px-4 pb-2">
                      <div className="text-center text-xl py-2 text-primary">
                        Subscriptions
                      </div>
                      <div>
                        list subs here
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
              <div className="md:grid md:grid-cols-2">
                  <div className="p-6 col-span-2">
                    <div className="border border-primary bg-white rounded-lg px-4 pb-2">
                      <div className="text-center text-xl py-2 text-primary">
                        Licences

                        <div className={"flex gap-2 float-right"}>
                          <Button
                            onClick={() => history.push(`/settings/account/${org._id}/purchase_license`)}
                            label="Purchase Licences"
                            colour="positive"
                          />

                          <Button
                            onClick={() => goToBillingPortal()}
                            label="Billing Portal"
                            colour="action"
                          />
                        </div>



                      </div>

                      <div>
                        <div className="sm:hidden">
                          <label htmlFor="tabs" className="sr-only">
                            Select a tab
                          </label>
                          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                          <select
                            id="tabs"
                            name="tabs"
                            defaultValue={tabs.find((t) => t.tab == tab).name}
                            className="block w-full rounded-md border-gray-300 focus:border-primary focus:ring-primary"
                          >
                            {tabs.map((t) => (
                              <option key={t.name}>{t.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="hidden sm:block">
                          <div className="border-b border-gray-200">
                            <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                              {tabs.map((t) => (
                                <a
                                  key={t.name}
                                  aria-current={t.tab === tab ? 'page' : undefined}
                                  onClick={() => setCurrentTab(t.tab)}
                                  className={classNames(
                                    t.tab === tab
                                      ? 'border-primary text-primary'
                                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                    'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium cursor-pointer',
                                  )}
                                >
                                  <t.icon
                                    aria-hidden="true"
                                    className={classNames(
                                      t.tab === tab ? 'text-primary' : 'text-gray-400 group-hover:text-gray-500',
                                      '-ml-0.5 mr-2 h-5 w-5',
                                    )}
                                  />
                                  <span>{t.name}</span>
                                </a>
                              ))}
                            </nav>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-around mb-4">
                        <div className=" p-4 rounded-xl text-center">
                          <div className="text-3xl">
                            {org.transactions
                            .filter(l => {
                              let days = dayjs(l.expiry).diff(dayjs(), "day");
                              let show = false;
                              switch(tab){
                                case "active":
                                  if(days >= 0){
                                    show = true;
                                  }
                                  break;
                                case "expiring":
                                  if(days >= 0 && days <= 28){
                                    show = true;
                                  }
                                  break;
                                case "expired":
                                  if(days < 0){
                                    show = true;
                                  }
                                  break;
                              }

                              if(show && l.subscription && l.subscription.user == "admin"){
                                return l;
                              }
                            })
                            .length}
                          </div>
                          <div className="text-xl text-primary">Admin Licences</div>
                        </div>
                        <div className=" p-4 rounded-xl text-center">
                          <div className="text-3xl">
                            {org.transactions
                            .filter(l => {
                              let days = dayjs(l.expiry).diff(dayjs(), "day");
                              let show = false;
                              
                              switch(tab){
                                case "active":
                                  if(days >= 0){
                                    show = true;
                                  }
                                  break;
                                case "expiring":
                                  if(days >= 0 && days <= 28){
                                    show = true;
                                  }
                                  break;
                                case "expired":
                                  if(days < 0){
                                    show = true;
                                  }
                                  break;
                              }

                              if(show && l.subscription && l.subscription.user == "user"){
                                return l;
                              }
                            })
                            .length}
                          </div>
                          <div className="text-xl text-primary">User Licences</div>
                        </div>
                      </div>
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Licence
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Assigned
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Receipt
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Purchased
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Expiring (Days Left)
                          </th>
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Actions</span>
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        {org.transactions.toSorted((a, b) => {
                          if (a.expiry < b.expiry) return -1;
                          if (a.expiry > b.expiry) return 1;
                          return 0;
                        }).filter(l => {
                          let days = dayjs(l.expiry).diff(dayjs(), "day");
                          switch(tab){
                            case "active":
                              if(days >= 0){
                                return l;
                              }
                            return;
                            case "expiring":
                              if(days >= 0 && days <= 28){
                                return l;
                              }
                            return;
                            case "expired":
                              if(days < 0){
                                return l;
                              }
                            return;
                          }
                        }).map((l, index) => {

                          return (
                            <tr key={index} className={`${l?.purchase?.hasPaymentFailure ? "bg-red-200" : ""} `}>
                              <td
                                className={classNames(
                                  index === 0 ? '' : 'border-t border-gray-200',
                                  'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell',
                                )}
                              >
                                {l.package.name}
                              </td>
                              <td
                                className={classNames(
                                  index === 0 ? '' : 'border-t border-gray-200',
                                  'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell',
                                )}
                              >
                                {l.assigned_to ? l.assigned_to.name : "Not Assigned"}
                              </td>
                              <td
                                className={classNames(
                                  index === 0 ? '' : 'border-t border-gray-200',
                                  'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell',
                                )}
                              >
                                {l.purchase ? <Button
                                  // onClick={() => window.open(l.purchase.receipt_url[l.purchase.receipt_url.length - 1].url, "_blank")}
                                  onClick={() => {

                                    let purchase = l.purchase ? l.purchase.receipt_url : [];
                                    let url = purchase[purchase.length - 1] ? purchase[purchase.length - 1].url : null;

                                      if(url){
                                        window.open(url, "_blank")
                                      }else{
                                        console.log("licence", l);
                                        console.log("purhcase", purchase);
                                        toast.error("No Recipient URL found!")
                                      }

                                    }
                                  }
                                  label="View"
                                  colour="action"
                                  icon={GoFilePdf}
                                />  : "No Assigned Receipt"}
                              </td>
                              <td
                                className={classNames(
                                  index === 0 ? '' : 'border-t border-gray-200',
                                  'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell',
                                )}
                              >
                                {l.createdAt && dayjs(l.createdAt).format("DD/MM/YYYY HH:mm")}
                              </td>
                              <td
                                className={classNames(
                                  index === 0 ? '' : 'border-t border-gray-200',
                                  'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell',
                                )}
                              >
                                {l.expiry && dayjs(l.expiry).format("DD/MM/YYYY HH:mm")} {l.expiry && `(${dayjs(l.expiry).diff(dayjs(), "day")} days)`}
                              </td>
                              {/*
                               <td
                               className={classNames(
                               index === 0 ? '' : 'border-t border-gray-200',
                               'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell',
                               )}
                               >
                               £{CommonFunctions.toFormattedFixed(l.package.price)}
                               </td>*/}
                              <td
                                className={classNames(
                                  index === 0 ? '' : 'border-t border-gray-200',
                                  'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell',
                                )}
                              >
                                {l?.purchase?.hasPaymentFailure ? (<>

                                </>) : (<>
                                  {l.expiry && dayjs(l.expiry).diff(dayjs(), "day") <= 28 && "Action"}
                                </>)}
                              </td>
                            </tr>
                          );
                        })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </Segment>
      </div>
    </>
  );
}

export default Account;

