/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState, useEffect, useMemo, useCallback } from "react";
import { Dialog, Transition, Menu } from "@headlessui/react";
import { Button, DateSelect, Dropdown, Input, SelectZero } from "../Forms";
import { NavLink, withRouter, useParams } from "react-router-dom";
import { XIcon } from "@heroicons/react/outline";
import ChainedContext from "../../contexts/chainedContext";
import AuthenticationReceiver from "../../plugins/clio_auth/_components/authenticationReceiver";
import DatePicker from "react-datepicker";
import Logo from "../../assets/plc-logo.svg";
import _buildNumber from "../../_buildNumber";
import {
  ClockIcon,
  PlayIcon,
  PlusIcon,
  StopIcon,
  XCircleIcon,
  DotsCircleHorizontalIcon,
  ArrowCircleLeftIcon,
  DocumentSearchIcon, DocumentTextIcon,
  ArrowDownIcon, DocumentIcon
} from "@heroicons/react/solid";
import CommonFunctions from "../../CommonFunctions";
import API from "../../api";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import { Authentication } from "../../plugins/clio_auth";
import _appConfig from "../../_appConfig";
import HtmlEditor from "../../components/widgets/HtmlEditor";
import Loader from "../Loader";
// import {renderTimeEntry} from "../widgets/timeEntries"

import styled, { createGlobalStyle } from 'styled-components';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SideBar({ navigation, sidebarOpen, setSidebarOpen, history, auth }) {
  const [goDark, setGoDark] = useState(false);
  const [timer, setTimer] = useState(false);
  const [existingTimer, setExistingTimer] = useState(null);
  const [allTimeEntries, setAllTimeEntries] = useState(false);

  const params = useParams();

  const [documentTemplates, setDocumentTemplates] = useState([]);
  const [documentTemplatesTypes, setDocumentTemplatesTypes] = useState([]);
  const [documentTemplatesType, setDocumentTemplatesType] = useState(null);
  const [documentTemplatesTypeParent, setDocumentTemplatesTypeParent] = useState(null);
  const [documentAreaType, setDocumentAreaType] = useState(null);
  const [documentContent, setDocumentContent] = useState("");
  const [documentHeader, setDocumentHeader] = useState("");
  const [dateVerbose, setDateVerbose] = useState("");
  const [documentFooter, setDocumentFooter] = useState("");
  const [openDocumentModal, setOpenDocumentModal] = useState(null);
  const [generatingDocument, setGeneratingDocument] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [showFooter, setShowFooter] = useState(false);

  const [mount, setMount] = useState(true);
  const [org, setOrg] = useState({});

  const GlobalStyle = createGlobalStyle`
`;

  const [DynamicGlobalStyle, setDynamicGlobalStyle] = useState(GlobalStyle);

  useEffect(() => {
    API.organisations.getMyOrg().then((organisation) => {
          setDynamicGlobalStyle(CommonFunctions.whitelabeling(organisation));
      setOrg(organisation)
    }).finally(() => setMount(false));
  },mount);

  const headerTemplates = useCallback(() => {
    return API.settings.letter_headers().list();
  }, []);

  const footerTemplates = useCallback(() => {
    return API.settings.letter_headers().list(); // TODO bring back just the templates for foorer
  }, []);

  const [timeModal, setTimeModal] = useState(false);
  // 900000 - 15 mins 1500000 - 25 mins
  const [timerClock, setTimerClock] = useState(0);

  const [data, setData] = useState({
    date: new Date(),
  });
  const [userList, setUserList] = useState([]);
  const [defaultAdminRate, setDefaultAdminRate] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [matterList, setMatterList] = useState([]);
  const [timeEntriesList, setTimeEntriesList] = useState([]);

  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    if (mountLoading) {
      Promise.all([
        API.users.getAll().catch((e) => CommonFunctions.handleError(e)),
        API.settings.get().catch((e) => CommonFunctions.handleError(e)),
        API.settings
          .activity_categories()
          .list()
          .catch((e) => CommonFunctions.handleError(e)),
        API.timeEntry.list().catch((e) => CommonFunctions.handleError(e)),
        API.matters.list().catch((e) => CommonFunctions.handleError(e)),
        API.settings.doc_types().list().catch((e) => CommonFunctions.handleError(e)),
        API.settings.doc_templates().list().catch((e) => CommonFunctions.handleError(e)),
      ]).then((res) => {
        if (res[0]) {
          setUserList(
            res[0].users.map((a) => {
              if(a.email == auth.user.email){
                setData({ ...data, assigned: a._id });
              }
              return {
                text: a.name,
                value: a._id,
                rate: a.rate ? a.rate : 0,
              };
            })
          );
        }

        if (res[1]) {
          setDefaultAdminRate(res[1].adminRate ? res[1].adminRate : 0);
        }

        if (res[2]) {
          setCategoryList(
            res[2].map((a) => {
              return {
                text: a.name,
                value: a._id,
              };
            })
          );
        }
        if (res[3]) {
          setTimeEntriesList(res[3].filter(e => e.complete !== true));
        }
        if (res[4]) {
          setMatterList(
            res[4].map((a) => {
              return {
                text: `#${a.display_number} - ${a.description}`,
                value: a._id,
              };
            })
          );
        }

        if(res[5]){
          setDocumentTemplatesTypes(res[5])
        }

        if(res[6]){
          setDocumentTemplates(res[6])
        }

      });

      setMountLoading(false);
    }
  }, [mountLoading]);

  useEffect(() => {
    let interval = null;
    if (timer) {
      data.start_time = new Date();

      interval = setInterval(
        () => setTimerClock((prevTime) => prevTime + 10),
        10
      );
    } else {
      data.end_time = new Date();
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if (localStorage.getItem("theme" === "light")) {
      setGoDark(false);
    } else if (localStorage.getItem("theme" === "dark")) {
      setGoDark(true);
    }
  }, []);

  useEffect(() => {
    if (goDark === true) {
      localStorage.setItem("theme", "dark");
    } else if (goDark === false) {
      localStorage.setItem("theme", "light");
    }
  }, [goDark]);

  let navFilter = useMemo(() => {

    return navigation.filter((e) => {
      if(e.permission === undefined){
        return true;
      }
      let perm = [];
      
      if(!Array.isArray(e.permission)){
        perm.push(e.permission);
      }else{
        perm = e.permission;
      }

      let allowed = false;
      
      perm.forEach((p) => {
        if(Authentication.can(p)){
          allowed = true;
        }
      });

      return allowed;

    } )

  }, [navigation]);

  const renderTimeEntry = () => {

    let time_unit_by = 360000; //6 mins
    let time_unit_total = parseInt(timerClock / time_unit_by);

    const onDateChange = (date) => {
      setData({
        ...data,
        due_date: date,
      });
    };

    const onChange = (e) => {
      setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleFinish = () => {
      API.timeEntry.finish(existingTimer).then((res) => {
        toast.success("Time Entry finished and converted to Activity Log on Matter");

        if(data.__generateDocument){
          setGeneratingDocument(true);
          API.matters.activities.documentGenerate(data.matter._id, res._activity, {
            template: data.__generateDocument.template,
            document_fields_map: data.__generateDocument.document_fields_map,
            content: data.__generateDocument.content,
            header_content: data.__generateDocument.header_content,
            footer_content: '<div style="width: 100%;">'+ data.__generateDocument.footer_content + "</div>",
            date_verbose: data.__generateDocument.date_verbose
          }).then((res) => {
            setGeneratingDocument(false);
            if(res.success) {
              setTimeModal(false);
              setMountLoading(true);
              setTimerClock(0);
              setExistingTimer(null);
              setData({ date: new Date() });
            }});
        }else{
          setTimeModal(false);
          setMountLoading(true);
          setTimerClock(0);
          setExistingTimer(null);
          setData({ date: new Date() });
        }
            
      })
    };

    const handleSubmit = () => {
      let start = dayjs(data.start_time);
      // let start = dayjs(new Date());
      let end = dayjs(data.end_time);
      // let end = dayjs(start.add(15, "minute"));
      let units = parseInt(end.diff(start) / time_unit_by);

      if (existingTimer) {
        let currentQty = parseInt(data.qty);
        let newQty = parseInt(units);
        API.timeEntry
          .update(existingTimer, {
            ...data,
            qty: newQty + currentQty,
            time: timerClock,
          })
          .then(() => {
            toast.success("Time Entry updated successfuly");
            setTimeModal(false);
            setMountLoading(true);
            setTimerClock(0);
            setExistingTimer(null);
            setData({ date: new Date() });
          })
          .catch((e) => CommonFunctions.handleError(e));
      } else {
        API.timeEntry
          .add({ ...data, qty: units, time: timerClock })
          .then(() => {
            toast.success("Time Entry added successfully");
            setTimeModal(false);
            setMountLoading(true);
            setTimerClock(0);
            setData({ date: new Date() });
          })
          .catch((e) => {
            CommonFunctions.handleError(e);
          });
      }
    };


    let cat = data.activity ? data.activity.category : data.category;
    let theCat = categoryList.filter(c => c.value === cat);

    return (
      <Transition.Root show={timeModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setTimeModal}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-primary py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              {theCat && theCat[0] && theCat[0].text === "Document" ? "Document" : "Time"} Entry
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={() => {setTimeModal(false); setAllTimeEntries(true);}}
                              >
                                <span className="sr-only">Back</span>

                                <ArrowCircleLeftIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                              <button
                                type="button"
                                className="rounded-md bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={() => setTimeModal(false)}
                              >
                                <span className="sr-only">Close panel</span>

                                <XCircleIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm">
                              Enter details from the time entry
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label
                                  htmlFor="duration"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Duration <br />1 Unit per{" "}
                                  {(
                                    "0" +
                                    Math.floor((time_unit_by / 60000) % 60)
                                  ).slice(-2)}{" "}
                                  Minutes
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="duration"
                                    id="duration"
                                    value={time_unit_total}
                                    className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                  <Button
                                    style={{ display: "inline-flex" }}
                                    className={`text-center w-500 mr-2 ${
                                      timer && "animate-pulse"
                                    }`}
                                    colour={timer ? "sidebarTimerActive" : "sidebarTimer"}
                                    // label={timerClock}
                                    label={`${(
                                      "0" +
                                      Math.floor((timerClock / 3600000) % 24)
                                    ).slice(-2)}:${(
                                      "0" +
                                      Math.floor((timerClock / 60000) % 60)
                                    ).slice(-2)}:${(
                                      "0" + Math.floor((timerClock / 1000) % 60)
                                    ).slice(-2)}`}
                                    onClick={() => {
                                      setTimer(!timer);
                                    }}
                                    disabled={false}
                                    icon={timer ? StopIcon : PlayIcon}
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="mt-1">
                                  <DateSelect
                                    required
                                    label="Date"
                                    name="date"
                                    value={data.activity ? data.activity.due_date : data.date}
                                    disabled={data.activity && data.activity._id}
                                    onChange={onDateChange}
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="mt-1">
                                  <Dropdown
                                    required
                                    label="Activity Category"
                                    name="category"
                                    value={data.activity ? data.activity.category : data.category}
                                    disabled={data.activity && data.activity._id}
                                    onChange={(e) => {
                                      setData({
                                        ...data,
                                        [e.target.name]: e.target.value,
                                      });
                                    }}
                                    options={categoryList}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Description
                                </label>
                                <div className="mt-1">
                                  <textarea
                                    id="description"
                                    name="description"
                                    rows={4}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    onChange={onChange}
                                    value={data.activity ? data.activity.description : data.description}
                                    disabled={data.activity && data.activity._id}
                                    defaultValue={""}
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="mt-1">
                                  <SelectZero
                                    required
                                    label="Matter"
                                    name="matter"
                                    options={matterList}
                                    value={data.matter ? (data.matter._id ? data.matter._id : data.matter) : ""}
                                    disabled={data.activity && data.activity._id}
                                    // error={errors.rateType}
                                    onChange={onChange}
                                  />
                                  {}
                                </div>
                              </div>
                              {(theCat && theCat[0] && theCat[0].text === "Document") && !data.activity &&
                              <div>
                                <div className="mb-4 flex px-3">
                                  <Menu
                                    as="div"
                                    className="relative inline-block text-left w-full"
                                  >
                                    <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                                      <DocumentSearchIcon
                                        className="-ml-1 mr-2 h-5 w-5"
                                        aria-hidden="true"
                                      />
                                      Find Document 
                                    </Menu.Button>

                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                          <Menu.Item>
                                            <div
                                              onClick={() => setDocumentAreaType("law")}
                                              className={
                                                "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                              }
                                            >
                                              Area of Law Templates
                                            </div>
                                          </Menu.Item>
                                          <Menu.Item>
                                            <div
                                              onClick={() => setDocumentAreaType("global")}
                                              className={
                                                "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                              }
                                            >
                                              Global Templates
                                            </div>
                                          </Menu.Item>
                                        </div>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </div>

                                {documentTemplatesType && documentAreaType === "law" ?
                                  <div className="mb-4 flex px-3">
                                    <Menu
                                      as="div"
                                      className="relative inline-block text-left w-full"
                                    >
                                      {documentTemplatesFiltered.length > 0 ? <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                                        <DocumentTextIcon
                                          className="-ml-1 mr-2 h-5 w-5"
                                          aria-hidden="true"
                                        />
                                        Select {documentTemplatesType} Template:
                                      </Menu.Button>

                                      :

                                      <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 bg-alert px-4 py-2 text-sm font-medium text-white shadow-sm cursor-not-allowed">
                                        <XIcon
                                          className="-ml-1 mr-2 h-5 w-5"
                                          aria-hidden="true"
                                        />
                                        {documentTemplatesType} Has No Templates!
                                      </Menu.Button>}

                                      {documentTemplatesFiltered.length > 0 &&

                                      <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                      >
                                        <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                          <div className="py-1">
                                            {documentTemplatesFiltered.map((template) => {
                                              return <Menu.Item>
                                              <div
                                                onClick={() => {
                                                  setOpenDocumentModal(template._id)
                                                  setDocumentContent(template.content);
                                                  setDocumentHeader(template.header_content);
                                                  setDocumentFooter(template.footer_content);
                                                  setData({
                                                    ...data,
                                                    document_fields_map: template.document_fields
                                                  })
                                                  /*
                                                API.matters.tasks
                                                  .updateStatus(params.matter_id, params.task_id, {sendEmailTemplate: template.code})
                                                  .then(() => setMountLoading(true))
                                                .catch((e) => CommonFunctions.handleError(e))}*/ }}
                                                className={
                                                  "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                                }
                                              >
                                                {template.name}
                                              </div>
                                            </Menu.Item>
                                            })}
                                          </div>
                                        </Menu.Items>
                                      </Transition>}
                                    </Menu>
                                  </div> : documentAreaType === "global" &&
                                  <div className="mb-4 flex px-3">
                                    <Menu
                                      as="div"
                                      className="relative inline-block text-left w-full"
                                    >
                                      <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                                        <DocumentTextIcon
                                          className="-ml-1 mr-2 h-5 w-5"
                                          aria-hidden="true"
                                        />
                                        Select Global Template:
                                      </Menu.Button>

                                      <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                      >
                                        <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                          <div className="py-1">
                                            {globalTemplates.map((template) => {
                                              return <Menu.Item>
                                              <div
                                                onClick={() => {
                                                  setOpenDocumentModal(template._id)
                                                  setDocumentContent(template.content);
                                                  setDocumentHeader(template.header_content);
                                                  setDocumentFooter(template.footer_content);
                                                  setData({
                                                    ...data,
                                                    document_fields_map: template.document_fields
                                                  })
                                                  /*
                                                API.matters.tasks
                                                  .updateStatus(params.matter_id, params.task_id, {sendEmailTemplate: template.code})
                                                  .then(() => setMountLoading(true))
                                                .catch((e) => CommonFunctions.handleError(e))}*/ }}
                                                className={
                                                  "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                                }
                                              >
                                                {template.name}
                                              </div>
                                            </Menu.Item>
                                            })}
                                          </div>
                                        </Menu.Items>
                                      </Transition>
                                    </Menu>
                                  </div> }

                                {documentAreaType === "law" && documentTemplatesTypeParent && <div className="mb-4 flex px-3">
                                  <Menu
                                    as="div"
                                    className="relative inline-block text-left w-full"
                                  >
                                    <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                                      <DocumentSearchIcon
                                        className="-ml-1 mr-2 h-5 w-5"
                                        aria-hidden="true"
                                      />
                                      Select Sub Category from {documentTemplatesTypeParent.name}
                                    </Menu.Button>

                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            <div
                                              onClick={() => {
                                                setDocumentTemplatesType(documentTemplatesTypeParent.name) }}
                                              className={
                                                "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                              }
                                            >
                                              {documentTemplatesTypeParent.name} ({documentTemplatesTypeParent.shortcode})
                                            </div>
                                          {documentTemplatesTypes.filter(e => e.archived != true && e.parent && e.parent._id == documentTemplatesTypeParent._id ).map((template) => {
                                            return <Menu.Item>
                                            <div
                                              onClick={() => {
                                                setDocumentTemplatesType(template.name) }}
                                              className={
                                                "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                              }
                                            >
                                              {template.name} ({template.shortcode})
                                            </div>
                                          </Menu.Item>
                                          })}
                                        </div>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </div>}

                                {documentAreaType === "law" && 

                              <div className="mb-4 flex px-3">
                                  <Menu
                                    as="div"
                                    className="relative inline-block text-left w-full"
                                  >
                                    <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                                      <DocumentSearchIcon
                                        className="-ml-1 mr-2 h-5 w-5"
                                        aria-hidden="true"
                                      />
                                      Select Area of Law
                                    </Menu.Button>

                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                          {documentTemplatesTypes.filter(e => e.archived != true).map((template) => {
                                            return <Menu.Item>
                                            <div
                                              onClick={() => setDocumentTemplatesTypeParent(template)}
                                              className={
                                                "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                              }
                                            >
                                              {template.name} ({template.shortcode})
                                            </div>
                                          </Menu.Item>
                                          })}
                                        </div>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </div>}

                              </div>}
                              {data.__generateDocument ? "Document Selected: This will be generated on Finish to link to the Matter and new Activity!" : "No Document Selected!"}
                              <div>
                                <div className="mt-1">
                                  <SelectZero
                                    reauired
                                    label="Firm User"
                                    name="firmUser"
                                    options={userList}
                                    value={data.firmUser}
                                    // error={errors.rateType}
                                    onChange={(e) => {
                                      const user = userList.find(
                                        (item) => item.value === e.target.value
                                      );
                                      setData({
                                        ...data,
                                        [e.target.name]: e.target.value,
                                        rate:
                                          data.defaultAdminRate !== true
                                            ? user
                                              ? (
                                                  time_unit_total *
                                                  (user.rate / 60)
                                                ).toFixed(2)
                                              : 0
                                            : data.rate,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="rate"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Rate
                                </label>
                                <div className="mt-1">
                                  <div className="relative mt-1 rounded-md shadow-sm">
                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                      <span className="text-gray-500 sm:text-sm">
                                        £
                                      </span>
                                    </div>
                                    <input
                                      required
                                      type="text"
                                      name="rate"
                                      id="rate"
                                      value={data.rate}
                                      onChange={onChange}
                                      className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      placeholder="0.00"
                                    />
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                      <span
                                        className="text-gray-500 sm:text-sm"
                                        id="price-currency"
                                      >
                                      </span>
                                    </div>
                                  </div>
                                  <br />
                                  <div className="relative flex items-start">
                                    <div className="flex h-5 items-center">
                                      <input
                                        id="nonBillable"
                                        name="nonBillable"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      />
                                    </div>
                                    <div className="ml-3 text-sm">
                                      <label
                                        htmlFor="nonBillable"
                                        className="font-medium text-gray-700"
                                      >
                                        Non-Billable
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {generatingDocument ? <div>
                      <Loader />
                      </div> :
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => setTimeModal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={handleSubmit}
                        >
                          {existingTimer ? "Update" : "Add"}
                        </button>
                        {existingTimer &&
                        <button
                          type="submit"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                          onClick={handleFinish}
                        >
                          Finish
                        </button>}
                        {data.activity &&
                        <button
                          type="submit"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          onClick={() => {history.push(`/matters/${data.matter._id}/activities/${data.activity._id}`); setTimeModal(false); }}
                        >
                          Activity
                        </button>}
                      </div>}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderAllTimeEntries = () => {
    return (
      <Transition.Root show={allTimeEntries} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setAllTimeEntries}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="bg-primary py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            All Time Entries
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-primary-700 text-plc-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setAllTimeEntries(false)}
                            >
                              <span className="sr-only">Close panel</span>

                              <XCircleIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm">
                            Below are the list of all the current time entries
                          </p>
                        </div>
                      </div>
                      <ul
                        role="list"
                        className="flex-1 divide-y divide-gray-200 overflow-y-auto"
                      >
                        {timeEntriesList.length > 0 ? timeEntriesList.map((entry, index) => (
                          <li
                            key={"entry_" + index}
                            className={`${
                              existingTimer === entry._id &&
                              timer &&
                              "animate-pulse"
                            }`}
                          >
                            <div className="group relative flex items-center py-6 px-5">
                              <a
                                // href={person.href}
                                className="-m-1 block flex-1 p-1"
                              >
                                <div
                                  className="absolute inset-0 group-hover:bg-gray-50"
                                  aria-hidden="true"
                                />
                                <div className="relative flex min-w-0 flex-1 items-center">
                                  <span className="relative inline-block flex-shrink-0">
                                    <ClockIcon
                                      className={`h-5 w-5 inline-block ${
                                        existingTimer === entry._id && timer
                                          ? "text-blue-400 group-hover:text-blue-500"
                                          : "text-orange-400 group-hover:text-orange-500"
                                      } `}
                                      aria-hidden="true"
                                    />
                                    {existingTimer === entry._id && timer && (
                                      <PlayIcon
                                        className="h-5 w-5 text-blue-400 group-hover:text-blue-500 inline-block"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </span>
                                  <div className="ml-4 truncate">
                                    <p className="truncate text-sm font-medium text-gray-900">
                                      #{entry.matter ? `${entry.matter.display_number} - ${entry.matter.description}`
                                        : "No Matter"}
                                    </p>
                                    <p className="truncate text-sm text-gray-500">
                                      {entry.description
                                        ? entry.description
                                        : "No Description"}
                                    </p>
                                    <p>{(existingTimer == entry._id) ? 
                                            `${(
                                              "0" +
                                              Math.floor((timerClock / 3600000) % 24)
                                            ).slice(-2)}:${(
                                              "0" +
                                              Math.floor((timerClock / 60000) % 60)
                                            ).slice(-2)}:${(
                                              "0" + Math.floor((timerClock / 1000) % 60)
                                            ).slice(-2)}`
                                            : 
                                            `${(
                                              "0" +
                                              Math.floor(
                                                ((entry.activity && entry.activity.time ? (entry.activity.time * 60000) : entry.time) / 3600000) % 24
                                              )
                                            ).slice(-2)}:${(
                                              "0" +
                                              Math.floor(
                                                ((entry.activity && entry.activity.time ? (entry.activity.time * 60000) : entry.time) / 60000) % 60
                                              )
                                            ).slice(-2)}:${(
                                              "0" +
                                              Math.floor(
                                                ((entry.activity && entry.activity.time ? (entry.activity.time * 60000) : entry.time) / 1000) % 60
                                              )
                                            ).slice(-2)}`
                                            }
                                            {" "}</p>
                                  </div>
                                </div>
                              </a>
                              <Menu
                                as="div"
                                className="relative ml-2 inline-block flex-shrink-0 text-left"
                              >
                                <Menu.Button className="group relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                  <span className="sr-only">
                                    Open options menu
                                  </span>
                                  <span className="flex h-full w-full items-center justify-center rounded-full">
                                    <DotsCircleHorizontalIcon
                                      className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Menu.Button>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute top-0 right-9 z-10 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            onClick={() => {
                                              if (timer) {
                                                setAllTimeEntries(false);
                                                setTimeModal(true);

                                                if(existingTimer == entry._id){
                                                  setTimer(false);
                                                }else{
                                                  setTimer(true);
                                                  setData(entry);
                                                  setExistingTimer(entry._id);
                                                  setTimerClock((entry.activity && entry.activity.time ? (entry.activity.time * 60000) : entry.time));
                                                }

                                              }else{
                                                setTimer(true);
                                                setData(entry);
                                                setExistingTimer(entry._id);
                                                setTimerClock((entry.activity && entry.activity.time ? (entry.activity.time * 60000) : entry.time));
                                              }
                                              
                                            }}
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm cursor-pointer"
                                            )}
                                          >
                                            {(existingTimer == entry._id) ? 
                                            `${(
                                              "0" +
                                              Math.floor((timerClock / 3600000) % 24)
                                            ).slice(-2)}:${(
                                              "0" +
                                              Math.floor((timerClock / 60000) % 60)
                                            ).slice(-2)}:${(
                                              "0" + Math.floor((timerClock / 1000) % 60)
                                            ).slice(-2)}`
                                            : 
                                            `${(
                                              "0" +
                                              Math.floor(
                                                ((entry.activity && entry.activity.time ? (entry.activity.time * 60000) : entry.time) / 3600000) % 24
                                              )
                                            ).slice(-2)}:${(
                                              "0" +
                                              Math.floor(
                                                ((entry.activity && entry.activity.time ? (entry.activity.time * 60000) : entry.time) / 60000) % 60
                                              )
                                            ).slice(-2)}:${(
                                              "0" +
                                              Math.floor(
                                                ((entry.activity && entry.activity.time ? (entry.activity.time * 60000) : entry.time) / 1000) % 60
                                              )
                                            ).slice(-2)}`
                                            }
                                            {" "}
                                            {timer ? (
                                              <StopIcon
                                                className="inline-block h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                aria-hidden="true"
                                              />
                                            ) : (
                                              <PlayIcon
                                                className="inline-block h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                aria-hidden="true"
                                              />
                                            )}
                                          </a>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            onClick={() => {
                                              console.log("ent", entry);
                                              if(existingTimer != entry._id){
                                                setTimer(false);
                                              }
                                              setData(entry);
                                              setExistingTimer(entry._id);
                                              setTimerClock(entry.activity && entry.activity.time ? (entry.activity.time * 60000) : entry.time);
                                              setAllTimeEntries(false);
                                              setTimeModal(true);
                                            }}
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm cursor-pointer"
                                            )}
                                          >
                                            Edit Entry
                                          </a>
                                        )}
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                          </li>
                        )) : 
                        <li>
                        <div className="group relative flex items-center py-6 px-5">
                          <a
                            // href={person.href}
                            className="-m-1 block flex-1 p-1"
                          >
                            <div
                              className="absolute inset-0 group-hover:bg-gray-50"
                              aria-hidden="true"
                            />
                            <div className="relative flex min-w-0 flex-1 items-center">
                              <span className="relative inline-block flex-shrink-0">
                                <XIcon
                                  className={`h-5 w-5 inline-block text-red-400 group-hover:text-blue-500`}
                                  aria-hidden="true"
                                />
                              </span>
                              <div className="ml-4 truncate">
                                <p className="truncate text-sm font-medium text-gray-900">
                                  No Time Entries
                                </p>
                                <p className="truncate text-sm text-gray-500">
                                  There are currently no active time entries, <br/>to start an entry click on the timer on the left hand side.
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </li>}
                      </ul>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const handleSaveData = (data, field) => {
    setData(a => {
      return {
        ...a,
        [field]: data
      }
    })
  }

  const previewContent = () => {

    let html = documentHeader + "<hr/>";
    html += documentContent + "<hr/>";
    html += documentFooter + "<hr/>";

    return html;

  }

  const documentModal = () => { 

    let template = documentTemplates.filter(e => e._id === openDocumentModal);

    return(
      <Transition.Root show={openDocumentModal != null && !generatingDocument} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenDocumentModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full mr-10 ml-10">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary sm:mx-0 sm:h-10 sm:w-10">
                      <DocumentIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full ">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        {template[0] && (template[0].document_type == "simple" ? "Document: " : "Form: ")}{template[0] && template[0].name}
                      </Dialog.Title>
                      <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                        <p className={`text-sm text-gray-500 ${template[0] && (template[0].document_type == "simple" ? "sm:col-span-2" : "sm:col-span-3")}`}>
                          {template[0] && template[0].document_type != "form" &&
                          <>
                          <label for="dateVerbose"><strong>Date:</strong></label>
                          <DatePicker
                            name="dateVerbose"
                            onChange={(date) => 
                              setDateVerbose(date)
                            }
                            label="Date"
                            selected={dateVerbose ? new Date(dateVerbose) : ""}
                            showPopperArrow={false}
                            shouldCloseOnSelect={true}
                            dateFormat="dd-MM-yy"
                            className={`relative w-full  p-2 rounded-lg bg-gray-100 border border-gray-300`}
                          />
                          <h1 className="border-solid border-2 border-grey-600 p-2 rounded text-med font-medium leading-6 text-gray-900 mt-5 mb-2 cursor-pointer" onClick={() => setShowHeader(!showHeader)}>
                            Header: <ArrowDownIcon className={`w-4 inline ${showHeader ? "" : "rotate-180"}`} />
                            <span className="block text-xs text-slate-400">Click to {showHeader ? "Hide" : "Show"}</span>
                          </h1>
                            
                            {showHeader &&

                              <HtmlEditor
                                content={documentHeader}
                                templates={headerTemplates}
                                options={{
                                  height: 400,
                                }}
                                onContentUpdate={(d) => setDocumentHeader(d)}
                              />
                            }

                            <h1 className="border-solid border-2 border-grey-600 p-2 rounded text-med font-medium leading-6 text-gray-900 mt-5 mb-2">
                              Content:
                            </h1>

                            <HtmlEditor
                              content={documentContent}
                              options={{
                                height: 400,
                              }}
                              onContentUpdate={(d) => setDocumentContent(d)}
                            />

                            <h1 className="border-solid border-2 border-grey-600 p-2 rounded text-med font-medium leading-6 text-gray-900 mt-5 mb-2 cursor-pointer" onClick={() => setShowFooter(!showFooter)}>
                              Footer: <ArrowDownIcon className={`w-4 inline ${showFooter ? "" : "rotate-180"}`} />
                              <span className="block text-xs text-slate-400">Click to {showFooter ? "Hide" : "Show"}</span>
                            </h1>

                            {showFooter &&
                              <HtmlEditor
                                content={documentFooter}
                                templates={footerTemplates}
                                options={{
                                  height: 400,
                                }}
                                onContentUpdate={(d) => setDocumentFooter(d)}
                              />

                            }

                          </>
                            
                          }

                          {template[0] && template[0].document_type == "form" &&
                          
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                  Field Type
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                  Field Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Content
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {template[0].document_fields.length ? (
                                template[0].document_fields.filter(f => f.type === "PDFTextField" || f.type === "PDFCheckBox").map((field, index) => {
          
                                  let fields = template[0].document_fields_map;
                                  let map = fields.find(f => f.field == field.name);

                                  return(
                                  <tr key={"field_" + index}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                      {field.type}
                                    </td>
                                    <td className="px-3 py-4 text-sm text-gray-500">
                                      {field.name}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[700px]">
                                    {field.type == "PDFTextField" &&
                                        <Input 
                                          value={map?.content || ""}
                                          onChange={(d) => {
                                            map.content = d.target.value;
                                            handleSaveData(fields.map(a => {
                                              if(a.field !== field.name) return a;
                                              return {
                                                ...a,
                                                content: d.target.value 
                                              }
                                            }), "document_fields_map")}
                                          }
                                        
                                        />}

                                        {field.type == "PDFCheckBox" &&
                                        <Input 
                                          type="checkbox"
                                          checked={map?.content || false}
                                          onChange={(d) => {
                                            map.content = d.target.checked;
                                            handleSaveData(fields.map(a => {
                                              if(a.field !== field.name) return a;
                                              return {
                                                ...a,
                                                content: d.target.checked
                                              }
                                            }), "document_fields_map")}
                                          }
                                        /> }
                                      
                                      
                                    </td>
                                  </tr>);
                                            
                                }
                                )
                              ) : (
                                <tr>
                                  <td
                                    colSpan={3}
                                    className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                                  >
                                    There are no fields to display.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>

                          }

                          {/* <iframe width="100%" height="500px" src={_appConfig.api_server + openDocumentFile} /> */}
                          {/* <DocViewer  pluginRenderers={DocViewerRenderers} documents={{uri: _appConfig.api_server + openDocumentFile}} /> */}
                        </p>  

                        {template[0] && template[0].document_type != "form" &&
                          <p className="text-sm text-gray-500 sm:col-span-1">
                            <h1 className="text-med font-medium leading-6 text-gray-900 mb-2">Email Preview:</h1>
                            <div style={{maxHeight: "550px"}} className="mt-5 overflow-y-scroll sun-editor-editable" dangerouslySetInnerHTML={{__html: previewContent()}}/>
                          </p>}

                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {/*  
                  <button
                    
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpenDocumentModal(null)}
                  >
                    Email Document
                  </button>*/}

                  <button
                    disabled={generatingDocument}
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setData({
                        ...data,
                        __generateDocument: {
                          template:openDocumentModal,
                          document_fields_map: data.document_fields_map,
                          content: documentContent,
                          header_content: documentHeader,
                          footer_content: documentFooter,
                          date_verbose: dateVerbose
                        }
                      });
                      setOpenDocumentModal(null)
                    }}
                  >Select Document</button>

                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpenDocumentModal(null)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    );
  }

  let documentTemplatesFiltered = documentTemplates.filter(e => 
    e.recipient && 
    Array.isArray(e.recipient) &&
    (e.recipient.some(recipient => recipient.label === documentTemplatesType) || e.recipient.some(recipient => recipient === documentTemplatesType)) &&
    !e.archived &&
    !e.global_template
  );

  let globalTemplates = documentTemplates.filter(e => 
    e.global_template
  );

  return (
    <>
      <DynamicGlobalStyle />
      {documentModal()}
      {renderTimeEntry()}
      {renderAllTimeEntries()}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-100 focus:outline-none">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div
                  onClick={() => {
                    history.push("/");
                    setSidebarOpen();
                  }}
                  className="tracking-widest text-gray-500 flex-shrink-0 flex flex-col items-center px-4 cursor-pointer border-b border-primary"
                >
                  {/*}<img src={Logo} alt="Logo" className="w-40" />*/}
                  <div className="pt-4 text-2xl text-center text-primary text-amber-600">
                    Debt Bridge (mobile)
                  </div>
                </div>
                <nav aria-label="Sidebar" className="mt-5">
                  <div className="px-2 space-y-1">
                    {navFilter.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        target={item.target}
                        className={classNames(
                          item.current
                            ? "bg-gray-100 text-gray-900"
                            : "text-primary hover:bg-gray-50 hover:text-gray-900",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )}
                        onClick={setSidebarOpen}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-amber-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "mr-4 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}{" "}
                        {item.badge && 
                          <span className={`ml-2 inline-flex items-center rounded-full ${item.badgeColor ? item.badgeColor : "bg-sidemenuBadgeZero text-sidemenuBadgeZeroText"} px-2.5 py-0.5 text-xs font-medium`}>
                            {item.badge}
                          </span>
                        }
                      </NavLink>
                    ))}
                  </div>
                </nav>
              </div>
              <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                <div className="flex-shrink-0 group block">
                  <div className="flex items-center">
                    <div className="ml-3">
                      <p className="text-base font-medium text-gray-400 group-hover:text-gray-900">
                        {auth.user.name}
                      </p>
                      <div
                        onClick={() => auth.logout()}
                        className="text-sm font-medium text-red-500 group-hover:text-gray-700"
                      >
                        Logout
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      <div className="hidden lg:flex lg:flex-shrink-0 h-full">
        <div className="flex flex-col w-52">
          <div className=" flex-1 flex flex-col min-h-0 border-r bg-sidemenuPrimary text-primary border-sidemenuPrimaryTitle">
            <div className="flex-1 flex flex-col pb-2 overflow-y-auto">
              <div
                onClick={() => {
                  history.push("/");
                  setSidebarOpen();
                }}
                className=" text-lg cursor-pointer flex flex-col items-center flex-shrink-0 px-2 tracking-widest text-sidemenuPrimaryText text-center mx-auto border-b border-sidemenuPrimaryTitle"
              >
                <img src={org && org.whitelabeling && org.whitelabeling.logo_url ? _appConfig.api_server + org.whitelabeling.logo_url : Logo} alt="Logo" className="p-2" />
                <div className="p-2 text-center text-sidemenuPrimaryTitle text-2lg ">
                  Pro Legal Flow {/* Debt Bridge */}
                </div>
              </div>
              <nav className="mt-5 flex-1" aria-label="Sidebar">
                <div className="px-2 space-y-4">
                  {navFilter.map((item) => (
                    <NavLink
                      key={item.name}
                      target={item.target}
                      to={item.href}
                      className={
                        "text-sidemenuPrimaryText group flex items-center px-2 py-2 text-base font-medium rounded-md hover:bg-sidemenuHover hover:text-sidemenuHoverText"
                      }
                      onClick={setSidebarOpen}
                    >
                      <item.icon
                        className={"text-sidemenuPrimaryText mr-4 h-6 w-6 hover:text-sidemenuHoverText"}
                        aria-hidden="true"
                      />
                      {/* <item.icon
                        className={classNames(
                          item.current
                            ? "text-amber-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "mr-4 h-6 w-6"
                        )}
                        aria-hidden="true"
                      /> */}
                      {item.name}{" "}
                      {item.badge && 
                      <span className={`ml-2 inline-flex items-center rounded-full ${item.badgeColor ? item.badgeColor : "bg-sidemenuBadgeZero text-sidemenuBadgeZeroText"} px-2.5 py-0.5 text-xs font-medium`}>
                        {item.badge}
                      </span>}
                    </NavLink>
                  ))}
                </div>
              </nav>
            </div>
            <div className="flex-shrink-0 flex flex-col border-t border-sidemenuPrimaryTitle justify-center items-center p-2">
              <div className="mb-4 flex justify-center w-full">
                <Button
                  className={`text-center mr-2 ${timer && "animate-pulse"}`}
                  colour={timer ? "sidebarTimerActive" : "sidebarTimer"}
                  // label={timerClock}
                  label={`${(
                    "0" + Math.floor((timerClock / 3600000) % 24)
                  ).slice(-2)}:${(
                    "0" + Math.floor((timerClock / 60000) % 60)
                  ).slice(-2)}:${(
                    "0" + Math.floor((timerClock / 1000) % 60)
                  ).slice(-2)}`}
                  onClick={() => {
                    setTimer(!timer);
                    if (timer) {
                      setTimeModal(true);
                    }
                  }}
                  disabled={false}
                  icon={timer ? StopIcon : PlayIcon}
                />
                <Button
                  className=" text-center"
                  colour={"sidebarTimerViewAll"}
                  onClick={() => {
                    setAllTimeEntries(true);
                  }}
                  disabled={false}
                  icon={ClockIcon}
                />
              </div>
              <div className="mb-4 flex justify-center w-full px-3">
                <Menu
                  as="div"
                  className="relative inline-block text-left w-full"
                >
                  <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                    <PlusIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Activity
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute left-0 bottom-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          <div
                            onClick={() => history.push("/contacts/add")}
                            className={
                              "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                            }
                          >
                            Create Contact
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div
                            onClick={() => history.push("/matters/add")}
                            className={
                              "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                            }
                          >
                            Create Matter
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div
                            onClick={() => setTimeModal(true)}
                            className={
                              "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                            }
                          >
                            Time Entry
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div
                            onClick={() => {
                              setTimeModal(true);

                              let cat = categoryList.filter(c => c.text === "Document");

                              setData({
                                ...data,
                                category: cat && [0] ? cat[0].value : ""
                              })
                            }}
                            className={
                              "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                            }
                          >
                            Document Entry
                          </div>
                        </Menu.Item>
                        {/*  
                        <Menu.Item>
                          <div
                            onClick={() => console.log("Open Modal")}
                            className={
                              "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                            }
                          >
                            Email Log
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div
                            onClick={() => console.log("Open Modal")}
                            className={
                              "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                            }
                          >
                            Phone Log
                          </div>
                        </Menu.Item>*/}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <div className="flex-shrink-0 flex flex-col border-t border-sidemenuPrimaryTitle justify-center items-center p-2">
              <div className="flex-shrink-0 w-full group block">
                <div className="flex items-center justify-center">
                  <div className=" px-4 py-2 text-center">
                    <p className="text-sm font-medium text-sidemenuPrimaryText my-2">
                      {auth.user.name} {auth.user && auth.user.groups && `[${auth.user.groups.join(", ")}]`}
                    </p>
                    <p className="text-xs italic text-sidemenuPrimaryText my-2">
                      {auth.user && auth.user.company && auth.user.company.name}
                    </p>
                    <p className="text-xs italic text-sidemenuPrimaryText my-2">
                      Build #{_buildNumber}
                    </p>
                    <div
                      onClick={() => auth.logout()}
                      className="rounded-lg px-4 py-2 cursor-pointer text-center text-md font-medium text-red-500 hover:bg-gray-200 bg-white"
                    >
                      Logout
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChainedContext(withRouter(SideBar), [
  [
    AuthenticationReceiver,
    (auth) => {
      return { auth };
    },
  ],
]);
