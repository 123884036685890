import React, { useState, useEffect } from "react";
import ContactReceiver from "../../../contexts/contact_context/Contact_Receiver";
import ChainedContext from "../../../contexts/chainedContext";
import Loader from "../../../components/Loader";
import API from "../../../api";
import CommonFunctions from "../../../CommonFunctions";
import { Button } from "../../../components/Forms";
import { ThemeTable } from "../../../components/Tables";
import Segment from "../../../components/Segment";
import dayjs from "dayjs";
import { PlusIcon } from "@heroicons/react/solid";
import ContactBar from "../components/ContactBar";
import ClientBreadcrumbs from "../components/ClientBreadcrumbs";

function Index({ match, history, contact }) {
  const [matters, setMatters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    filter_active:    true,
    filter_hold:      false,
    filter_closed:    false,
    filter_archived:  false,
  })

  useEffect(() => {
    Promise.all([API.contacts.matters.list(match.params.contact_id)])
      .then((res) => {
        setMatters(res[0]);
      })
      .catch((e) => CommonFunctions.handleError(e))
      .finally(() => {
        setLoading(false);
      });
  }, [match.params.id]);

  const filteredMatters = matters.filter((matter) => {
    if (filters.filter_active && matter.status === 'Open') return true;
    if (filters.filter_hold && matter.status === 'On Hold') return true;
    if (filters.filter_closed && matter.status === 'Closed') return true;
    if (filters.filter_archived && matter.status === 'Archived') return true;
    return false;
  });

  return (
    <div className="">
      <ClientBreadcrumbs panes={[
        { name: `Client - ${contact && `${contact.name ? contact.name : `${contact.first_name} ${contact.last_name}`} ${contact.suffix ? contact.suffix : ""}`}`, href: `/contacts/${contact._id}`, current: false },
        { name: "Matters", current: true}
      ]} />
      <Segment>
        <ContactBar contact={contact} />
        <div className="">
          <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
            <span className="my-auto text-xl">Matter List</span>
            <Button
              colour="positive"
              label="Create"
              onClick={() => history.push(`${match.url}/create`)}
              className="ml-auto"
              icon={PlusIcon}
            />
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
        </div>
          
          <div className="col-span-2">
              <div className="w-fit ml-auto">
                <div className="relative inline-flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="filter_active"
                      aria-describedby="comments-description"
                      name="filter_active"
                      type="checkbox"
                      checked={filters.filter_active}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onChange={() => {
                        setFilters(
                          {
                            ...filters, 
                            filter_active: filters.filter_active
                              ? !filters.filter_active
                              : true,
                          },
                        );
                      }}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor="filter_active" className="font-medium text-gray-900">
                      Active
                    </label>
                  </div>
                </div>
                <div className="relative inline-flex items-start ml-4">
                  <div className="flex h-6 items-center">
                    <input
                      id="filter_hold"
                      aria-describedby="comments-description"
                      name="filter_hold"
                      type="checkbox"
                      checked={filters.filter_hold}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onChange={() => {
                        setFilters(
                          {
                            ...filters, 
                            filter_hold: filters.filter_hold
                              ? !filters.filter_hold
                              : true,
                          },
                        );
                      }}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor="filter_hold" className="font-medium text-gray-900">
                      On Hold
                    </label>
                  </div>
                </div>
                <div className="relative inline-flex items-start ml-4">
                  <div className="flex h-6 items-center">
                    <input
                      id="filter_closed"
                      aria-describedby="comments-description"
                      name="filter_closed"
                      type="checkbox"
                      checked={filters.filter_closed}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onChange={() => {
                        setFilters(
                          {
                            ...filters, 
                            filter_closed: filters.filter_closed
                              ? !filters.filter_closed
                              : true,
                          },
                        );
                      }}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor="filter_closed" className="font-medium text-gray-900">
                      Closed
                    </label>
                  </div>
                </div>
                <div className="relative inline-flex items-start ml-4">
                  <div className="flex h-6 items-center">
                    <input
                      id="filter_archived"
                      aria-describedby="comments-description"
                      name="filter_archived"
                      type="checkbox"
                      checked={filters.filter_archived}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onChange={() => {
                        setFilters(
                          {
                            ...filters, 
                            filter_archived: filters.filter_archived
                              ? !filters.filter_archived
                              : true,
                          },
                        );
                      }}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor="filter_archived" className="font-medium text-gray-900">
                      Archived
                    </label>
                  </div>
                </div>
              </div>
            </div>
          
        {loading ? (
          <Loader />
        ) : (
          <ThemeTable
            header={[
              { text: "Display Number (Clio)", value: "display_number" },
              { text: "Debtors", value: "debtors" },
              { text: "Status", value: "status" },
              { text: "Date", value: "open_date" },
              { text: "Practice Area", value: "practice_area_name" },
              { text: "Dealing", value: "name" },
            ]}
            body={filteredMatters.map((item) => {
              return{
              ...item,
              debtors: item.debtors.length > 0 ? item.debtors.join(", ") : "No Debtors",
              display_number: item.display_number ? item.display_number : item.client_reference,
              name: item.originating_attorney
                ? item.originating_attorney.name
                : "--",
              p_area_name: item.practice_area
                ? item.practice_area.name
                : "--",
              open_date: dayjs(item.open_date).format("DD/MM/YYYY HH:mm"),
            }})}
            action={(data) => history.push(`/matters/${data._id}`)}
            noDataMessage="No Matters..."
          />
        )}
      </Segment>
    </div>
  );
}
export default ChainedContext(Index, [
  [
    ContactReceiver,
    (contact) => {
      return { contact };
    },
  ],
]);
