import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../../api";
import CommonFunctions from "../../../CommonFunctions";
import { Button, SelectTwo} from "../../../components/Forms";
import { PencilIcon, PlusIcon } from "@heroicons/react/solid";
import Segment from "../../../components/Segment";
import ShortcodeDropdown from "../../../components/widgets/editorShortcodes/editorShortcodes_SunEditor";
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import HtmlEditor from "../../../components/widgets/HtmlEditor"; // Import Sun Editor's CSS File

export default function AddLetterHeading({ history }) {

  const params = useParams();

  const editorRef = useRef();
  const getSunEditorInstance = (sunEditor) => {
    editorRef.current = sunEditor;
  };

  const [data, setData] = useState({});
  // const [itemData, setItemData] = useState(initialState);

  const [editing, setEditing] = useState(false);
  const [mount, setMount] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mount && params.id) {
      setMount(false);
      setLoading(true);
      setEditing(true);

      API.settings
        .correspondents()
        .get(params.id)
        .then((res) => {
          return setData({
            	name: res.name,
              // file: res.file,
              type: res.type,
            	// content: res.content ? getHtml(res.content) : "",
            	// content: res.content ? res.content : "",
            	description: res.description,
          	})
        })
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setLoading(false));
    }
  }, [mount, loading]);

  const handleChange = (e) => {console.log("update")
    setData({ ...data, [e.target.name]: e.target.value });}

  const handleDropdownItemClick = (shortcode) => {
    if (editorRef.current) {
      const currentContents = editorRef.current.getContents();
      const updatedContents = currentContents + shortcode;
      editorRef.current.setContents(updatedContents);
    }
  }; 

  return (
    <Segment>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Correspondents Settings
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Enter Type of Correspondent
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <form action="#" method="POST">
              <div className="shadow sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Correspondent Name
                      </label> 
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="name"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={data.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Type
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <SelectTwo
                          field="type"
                          value={data.type}
                          options={[
                            {text: "Debtor", value: "debtor"},
                            {text: "Contact", value: "contact"},
                            {text: "Matter", value: "matter"},
                            {text: "User", value: "user"},
                            {text: "Court", value: "court"}
                          ]}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="description"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={data.description}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  {/*  
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="company-website"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Content
                      </label>
                      <div className="mt-1 rounded-md shadow-sm">
                        <HtmlEditor
                          onContentUpdate={d => {
                            setData(a => {
                              return {
                                ...a,
                                content: d
                              }
                            })
                          }}
                          content={data.content}
                          options={{
                            height: 400,
                          }}
                        />
                      </div>
                    </div>
                  </div>*/}
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <Button
                    className={`inline-flex justify-center rounded-md border border-transparent ${editing ? "bg-orange-600 hover:bg-orange-700 focus:ring-orange-500" : "bg-green-600 hover:bg-green-700 focus:ring-green-500"} py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
                    icon={editing ? PencilIcon : PlusIcon}
                    label={editing ? "Save" : "Add"}
                    colour={editing ? "warning" : "positive"}
                    onClick={() => {
                      let formData = new FormData();
                      let sendData = {
                        name: data.name,
                        content: data.content,
                        type: data.type,
                        // content: draftToHtml(
                          // convertToRaw(data.content.getCurrentContent())
                        // ),
                        description: data.description,
                        org_id: params.org_id ? params.org_id : undefined,
                      };

                      formData.append("data", JSON.stringify(sendData));
                      formData.append("file", data.file);

                      if (editing) {
                        API.settings
                          .correspondents()
                          .edit(params.id, formData)
                          .then((res) =>
                            history.push(params.org_id ? `/admin/organisations/${params.org_id}/correspondents` : "/settings/correspondents")
                          )
                          .catch((e) => CommonFunctions.handleError(e));
                      } else {
                        API.settings
                          .correspondents()
                          .add(formData)
                          .then((res) =>
                            history.push(params.org_id ? `/admin/organisations/${params.org_id}/correspondents` : "/settings/correspondents")
                          )
                          .catch((e) => CommonFunctions.handleError(e));
                      }
                    }}
                  />
                  {/* {editing ? <><PencilIcon className="h-4 mr-2" />{" "}Edit</>: <><PlusIcon className="h-4 mr-2" />{" "}Add</>}</button> */}
                </div>
            </form>
          </div>
        </div>
      </div>
    </Segment>
  );
}
